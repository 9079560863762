import {
  TextField,
  Button,
  Grid2 as Grid,
  Switch,
  FormControlLabel,
  FormControl,
  Typography,
  Box,
  FormLabel,
  Divider,
  Stack,
} from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import SelectProvince from './SelectProvince';

const InserisciDatiCliente = ({
  hook,
  noleggio,
  setNoleggio,
  viewMode = 'full',
}) => {
  const { formData, errors, handleInputChange, handleCopyAddress } = hook;

  const toggleSwitchSdd = e => {
    if (e.target.checked) {
      setNoleggio({
        ...noleggio,
        flg_sdd: true,
      });
    } else {
      setNoleggio({
        ...noleggio,
        flg_sdd: false,
      });
      handleInputChange({
        target: {
          name: 'iban',
          value: '',
        },
      });
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="ragione_sociale">Ragione Sociale</FormLabel>
            <TextField
              placeholder="Ragione Sociale"
              name="ragione_sociale"
              variant="outlined"
              value={formData?.ragione_sociale}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.ragione_sociale}
              helperText={errors.ragione_sociale}
              slotProps={{
                input: {
                  readOnly: viewMode === 'read' ? true : false,
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="cf">Codice Fiscale</FormLabel>
            <TextField
              placeholder="Codice Fiscale"
              name="cf"
              value={formData?.cf}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.cf}
              helperText={errors.cf}
              slotProps={{
                input: {
                  readOnly: viewMode === 'read' ? true : false,
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="piva">Partita Iva</FormLabel>
            <TextField
              placeholder="Partita IVA"
              name="piva"
              value={formData?.piva}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.piva}
              helperText={errors.piva}
              slotProps={{
                input: {
                  readOnly: viewMode === 'read' ? true : false,
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="mail">Email</FormLabel>
            <TextField
              placeholder="Email"
              name="mail"
              value={formData?.mail}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.mail}
              helperText={errors.mail}
              slotProps={{
                input: {
                  readOnly: viewMode === 'read' ? true : false,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="mail">PEC</FormLabel>
            <TextField
              placeholder="Pec"
              name="pec"
              value={formData?.pec}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.pec}
              helperText={errors.pec}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="telefono">Telefono</FormLabel>
            <TextField
              placeholder="Telefono"
              name="telefono"
              value={formData?.telefono}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.telefono}
              helperText={errors.telefono}
              slotProps={{
                input: {
                  readOnly: viewMode === 'read' ? true : false,
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, mb: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: 'Poppins-SemiBold', fontSize: 16 }}
        >
          Inserisci Indirizzi:
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 3 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_indirizzo">Indirizzo Sede</FormLabel>
            <TextField
              placeholder="Indirizzo Sede"
              name="sede_indirizzo"
              value={formData?.sede_indirizzo}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_indirizzo}
              helperText={errors.sede_indirizzo}
              slotProps={{
                input: {
                  readOnly: viewMode === 'read' ? true : false,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 6, sm: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_civico">N. Civico</FormLabel>
            <TextField
              placeholder="N. Civico"
              name="sede_civico"
              value={formData?.sede_civico}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_civico}
              helperText={errors.sede_civico}
              slotProps={{
                input: {
                  readOnly: viewMode === 'read' ? true : false,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 6, sm: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_cap">CAP</FormLabel>
            <TextField
              placeholder="CAP"
              name="sede_cap"
              value={formData?.sede_cap}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_cap}
              helperText={errors.sede_cap}
              slotProps={{
                input: {
                  readOnly: viewMode === 'read' ? true : false,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_citta">Città</FormLabel>
            <TextField
              placeholder="Città"
              name="sede_citta"
              value={formData?.sede_citta}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_citta}
              helperText={errors.sede_citta}
              slotProps={{
                input: {
                  readOnly: viewMode === 'read' ? true : false,
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 3 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_provincia">Provincia</FormLabel>
            <SelectProvince
              name="sede_provincia"
              placeholder="Provincia"
              value={formData?.sede_provincia}
              onChange={handleInputChange}
              errors={errors.sede_provincia}
              readOnly={viewMode === 'read' ? true : false}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, mb: 2 }}>
        <Button
          startIcon={<CopyAll />}
          variant="contained"
          color="info"
          size="small"
          onClick={() => {
            handleCopyAddress();
          }}
        >
          Copia Indirizzo Sede
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 3 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="locazione_indirizzo">
              Indirizzo Locazione
            </FormLabel>
            <TextField
              placeholder="Indirizzo Locazione"
              name="locazione_indirizzo"
              value={formData?.locazione_indirizzo}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.locazione_indirizzo}
              helperText={errors.locazione_indirizzo}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 6, sm: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="locazione_civico">N. Civico</FormLabel>
            <TextField
              placeholder="N. Civico"
              name="locazione_civico"
              value={formData?.locazione_civico}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.locazione_civico}
              helperText={errors.locazione_civico}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 6, sm: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="locazione_cap">CAP</FormLabel>
            <TextField
              placeholder="CAP"
              name="locazione_cap"
              value={formData?.locazione_cap}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.locazione_cap}
              helperText={errors.locazione_cap}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="locazione_citta">Città</FormLabel>
            <TextField
              placeholder="Città"
              name="locazione_citta"
              value={formData?.locazione_citta}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.locazione_citta}
              helperText={errors.locazione_citta}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 3 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="locazione_provincia">Provincia</FormLabel>
            <SelectProvince
              name="locazione_provincia"
              placeholder="Provincia"
              value={formData?.locazione_provincia}
              onChange={handleInputChange}
              errors={errors.locazione_provincia}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Divider orientation="horizontal" flexItem sx={{ mt: 4, mb: 3 }} />

      <Box>
        <Typography
          variant="h6"
          sx={{ fontFamily: 'Poppins-SemiBold', fontSize: 16 }}
        >
          Dettagli Pagamento
        </Typography>
      </Box>
      <Grid container spacing={8}>
        <Grid size={{ xs: 12, sm: 8 }}>
          <FormControlLabel
            sx={{ ml: 0 }}
            control={
              <Switch
                name="flg_sdd"
                checked={noleggio?.flg_sdd == 1 ? true : false}
                onChange={toggleSwitchSdd}
              />
            }
            labelPlacement="start"
            label={
              <Box sx={{ mt: 1, mr: 4 }}>
                <Stack direction="row">
                  Vuoi attivare l'addebito diretto&nbsp;
                  <Typography sx={{ color: 'primary.main' }}>SDD?</Typography>
                </Stack>
                <Typography variant="caption">
                  L'attivazione dell'addebito automatico avrà una spesa di
                  servizio di € 1,00/mese
                </Typography>
              </Box>
            }
          />
        </Grid>
        {noleggio?.flg_sdd ? (
          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="cf">IBAN</FormLabel>
              <TextField
                placeholder="IBAN"
                name="iban"
                value={formData?.iban}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.iban}
                helperText={errors.iban}
              />
            </FormControl>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};

export default InserisciDatiCliente;
