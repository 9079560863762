const createAsyncState = () => ({
  loading: false,
  error: null,
  success: false,
  data: null,
});

const handlePending = state => {
  state.loading = true;
  state.error = null;
  state.success = false;
  state.data = null;
};

const handleFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.success = true;
  state.data = action.payload;
};

const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
  state.success = false;
  state.data = null;
};

export { createAsyncState, handlePending, handleFulfilled, handleRejected };
