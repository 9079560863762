import { Stack, Grid2 as Grid, Typography, Box, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FirmaDocumento from './FirmaDocumento';
import CambiamentiRichiestaModifica from './CambiamentiRichiestaModifica';

const RichiestaModificaStep2 = ({
  onInoltraRichiesta,
  isInoltraLoading,
  noleggioData,
  richiestaModifica,
}) => {
  return (
    <Stack alignContent={'center'}>
      <Typography
        variant="body1"
        color="textPrimary"
        sx={{ fontWeight: 'bold' }}
      >
        Visualizza Riepilogo Richiesta di Modifica
      </Typography>
      <Typography variant="body2" color="textSecondary">
        In questa schermata sono mostrati gli importi attuali e gli importi
        aggiornati al termine della richiesta.
      </Typography>

      <CambiamentiRichiestaModifica
        noleggioData={noleggioData}
        richiestaModifica={richiestaModifica}
      />

      <Divider sx={{ my: 4 }} />

      <Box>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }} sx={{ alignContent: 'center' }}>
            <Box>
              <Typography fontWeight="bold">Firma Documenti</Typography>
              <Typography variant="body2" color="textSecondary">
                Indica la modalità con cui vorrai firmare i documenti per la
                modifica della pratica
              </Typography>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <FirmaDocumento size={6} spacing={2} />
          </Grid>
        </Grid>
      </Box>

      <Stack justifyContent={'center'} alignItems={'center'} sx={{ mt: 4 }}>
        <Typography color="primary.main">
          Vuoi confermare e inoltrare le modifiche?
        </Typography>

        <LoadingButton
          variant="contained"
          onClick={onInoltraRichiesta}
          loading={isInoltraLoading}
          sx={{ mt: 2 }}
          size="small"
        >
          INOLTRA RICHIESTA MODIFICA
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default RichiestaModificaStep2;
