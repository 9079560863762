import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  completaRichiesta,
  confermaRichiesta,
  confermaRichiestaAzienda,
  confermaRichiestaMediatore,
  getPdfProposta,
  salvaRichiesta,
  salvaRichiestaAzienda,
  salvaRichiestaMediatore,
} from '../../../redux/noleggi/api';
import LoadingMask from '../../../components/LoadingMask';
import IntestazioneNoleggio from './IntestazioneNoleggio';
import InfoIntestazioneNoleggio from './InfoIntestazioneNoleggio';
import { Close, Edit, Print, Send } from '@mui/icons-material';
import useResizer from '../../../hooks/useResizer';
import { useDispatch } from 'react-redux';
import { showAlertDialog, showSnackbar } from '../../../redux/app/slice';
import { downloadBlob } from '../../../utils/download';
import IntestazioneNoleggioVerticale from './IntestazioneNoleggioVerticale';
import ImportoCanoneMobile from './ImportoCanoneMobile';
import useAuth from '../../../hooks/useAuth';
import CompilaDatiRichiestaFornitore from './CompilaDatiRichiestaFornitore';
import CompilaDatiRichiestaCliente from './CompilaDatiRichiestaCliente';
import CompilaDatiRichiestaMediatore from './CompilaDatiRichiestaMediatore';

export default function DefinizioneProposta({
  writeMode,
  noleggio,
  setNoleggio,
  setViewMode,
}) {
  const theme = useTheme();
  const { user } = useAuth();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));

  const mainContentRef = useRef(null);
  useResizer(mainContentRef);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filePreventivo, setFilePreventivo] = useState(null);
  const [triggerUpdateDati, setTriggerUpdateDati] = useState(false);
  const componentCompilazione = useRef(null);

  const onNoleggioLoaded = noleggio => {
    setNoleggio(noleggio);
    setTriggerUpdateDati(true);
  };

  const onCompletaRichiesta = async () => {
    setIsLoading(true);
    try {
      const result = await completaRichiesta(noleggio);
      onNoleggioLoaded(result);
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onSalvaRichiesta = async (explicit = true) => {
    const validationResult = componentCompilazione.current.validationForm();

    if (validationResult == -1) {
      let snackBarMessage =
        user.tipologia === 'A'
          ? 'Devi inserire i tuoi dati per inviare la proposta'
          : 'Devi inserire i dati del cliente per inviare la proposta';
      dispatch(
        dispatch(
          showSnackbar({
            message: snackBarMessage,
            severity: 'error',
          })
        )
      );
      return;
    }

    if (validationResult == -2) {
      dispatch(
        dispatch(
          showSnackbar({
            message:
              'Devi inserire i dati del fornitore per inviare la proposta',
            severity: 'error',
          })
        )
      );
      return;
    }

    if (explicit) {
      if (noleggio.id_stato == 'IB') {
        dispatch(
          showAlertDialog({
            btnConfermaText: 'SALVA PROPOSTA',
            btnAnnullaText: 'ANNULLA',
            title: 'Salva Proposta',
            message: 'Vuoi salvare la proposta di noleggio?',
            onConfirm: () => {
              confirmSalvaRichiesta(validationResult, explicit);
            },
          })
        );
        return;
      }
    }

    return confirmSalvaRichiesta(validationResult, explicit);
  };

  const confirmSalvaRichiesta = async (validationResult, explicit = true) => {
    setIsLoading(true);
    try {
      let result = null;
      switch (user.tipologia) {
        case 'F':
          result = await salvaRichiesta(validationResult);
          break;
        case 'A':
          result = await salvaRichiestaAzienda(validationResult);
          break;
        case 'M':
          result = await salvaRichiestaMediatore(validationResult);
          break;
      }
      onNoleggioLoaded(result);

      if (explicit) {
        dispatch(
          showSnackbar({
            message: 'Proposta di noleggio salvata con successo',
            severity: 'success',
          })
        );
      }

      return result;
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onScaricaProposta = async id => {
    setIsLoading(true);
    try {
      const pdfData = await getPdfProposta(id);
      downloadBlob(pdfData, 'application/pdf', 'PropostaNoleggio.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    navigate(-1);
  };

  const onInviaProposta = () => {
    const validationResult = componentCompilazione.current.validationForm(true);

    if (validationResult == -1) {
      let snackBarMessage =
        user.tipologia === 'A'
          ? 'Devi inserire i tuoi dati per inviare la proposta'
          : 'Devi inserire i dati del cliente per inviare la proposta';
      dispatch(
        dispatch(
          showSnackbar({
            message: snackBarMessage,
            severity: 'error',
          })
        )
      );
      return;
    }

    if (validationResult == -2) {
      dispatch(
        dispatch(
          showSnackbar({
            message:
              'Devi inserire i dati del fornitore per inviare la proposta',
            severity: 'error',
          })
        )
      );
      return;
    }

    if (!noleggio.flg_preventivo && filePreventivo == null) {
      dispatch(
        showSnackbar({
          message: 'Devi caricare il preventivo per inviare la proposta',
          severity: 'error',
        })
      );
      return;
    }

    if ((noleggio.dettagli ?? []).length == 0) {
      dispatch(
        showSnackbar({
          message: 'Devi configurare i beni e servizi per inviare la proposta',
          severity: 'error',
        })
      );
      return;
    }

    dispatch(
      showAlertDialog({
        btnConfermaText: 'INOLTRA PROPOSTA DI NOLEGGIO',
        btnAnnullaText: 'ANNULLA',
        title: 'Sei sicuro di voler inoltrare la Proposta di Noleggio?',
        message:
          'Per essere attivata, la proposta di Noleggio dovrà essere valutata positiamente da Doinn Rental',
        onConfirm: () => {
          confirmInviaProposta(validationResult);
        },
      })
    );
  };

  const confirmInviaProposta = async validationResult => {
    setIsLoading(true);
    try {
      let result = null;
      switch (user.tipologia) {
        case 'F':
          result = await confermaRichiesta(validationResult);
          break;
        case 'A':
          result = await confermaRichiestaAzienda(validationResult);
          break;
        case 'M':
          result = await confermaRichiestaMediatore(validationResult);
          break;
      }
      onNoleggioLoaded(result);

      dispatch(
        showAlertDialog({
          btnConfermaText: 'OK',
          btnAnnullaText: null,
          title: 'La Pratica di Proposta Noleggio è stata inoltrata',
          message:
            'La Pratica di Proposta Noleggio è stata inoltrata al Noleggiatore. Segui lo status della valutazione nella sezione "Pratiche Inoltrate"',
        })
      );

      setViewMode('read');

      return result;
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onVisualizzaRiepilogo = async () => {
    const result = await onSalvaRichiesta(false);
    if (result != null) {
      onScaricaProposta(result.id);
    }
  };

  const getCompilaDati = () => {
    switch (user?.tipologia) {
      case 'F':
        return (
          <CompilaDatiRichiestaFornitore
            ref={componentCompilazione}
            noleggio={noleggio}
            setNoleggio={setNoleggio}
            filePreventivo={filePreventivo}
            setFilePreventivo={setFilePreventivo}
            triggerUpdateDati={triggerUpdateDati}
            setTriggerUpdateDati={setTriggerUpdateDati}
          />
        );
      case 'A':
        return (
          <CompilaDatiRichiestaCliente
            ref={componentCompilazione}
            noleggio={noleggio}
            setNoleggio={setNoleggio}
            filePreventivo={filePreventivo}
            setFilePreventivo={setFilePreventivo}
            triggerUpdateDati={triggerUpdateDati}
            setTriggerUpdateDati={setTriggerUpdateDati}
          />
        );
      case 'M':
        return (
          <CompilaDatiRichiestaMediatore
            ref={componentCompilazione}
            noleggio={noleggio}
            setNoleggio={setNoleggio}
            filePreventivo={filePreventivo}
            setFilePreventivo={setFilePreventivo}
            triggerUpdateDati={triggerUpdateDati}
            setTriggerUpdateDati={setTriggerUpdateDati}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {isLoading && <LoadingMask />}
      <Stack sx={{ pt: 3, px: matchMd ? 3 : 1 }} ref={mainContentRef}>
        {matchMd ? (
          <Box>
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography component="h2" variant="h4" sx={{ mb: 2 }}>
                {writeMode == 'new'
                  ? 'Nuova Proposta di Noleggio'
                  : writeMode == 'clone'
                    ? 'Clona Proposta di Noleggio'
                    : 'Proposta di Noleggio '}
              </Typography>
              <Stack flexDirection="row">
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2 }}
                  size="small"
                  onClick={onSalvaRichiesta}
                  startIcon={<Edit />}
                >
                  SALVA PROPOSTA
                  {noleggio.id_stato == 'IB' && ' IN BOZZA'}
                </Button>
                {noleggio.id_stato == 'DC' && (
                  <Button
                    variant="contained"
                    sx={{ mr: 2 }}
                    size="small"
                    onClick={onInviaProposta}
                    startIcon={<Send />}
                  >
                    INOLTRA PROPOSTA DI NOLEGGIO
                  </Button>
                )}

                <IconButton
                  size="small"
                  onClick={onVisualizzaRiepilogo}
                  variant="contained"
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  <Print />
                </IconButton>

                <IconButton size="small" onClick={onClose}>
                  <Close />
                </IconButton>
              </Stack>
            </Stack>

            {noleggio.id_stato == 'DC' && (
              <Box sx={{ mb: 2, px: matchXl ? 1.5 : 0 }}>
                <InfoIntestazioneNoleggio
                  noleggio={noleggio}
                  dettagli={noleggio.dettagli ?? []}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <Stack
              flexDirection="row"
              justifyContent="end"
              alignItems={'end'}
              gap={1}
              sx={{ mb: 2 }}
            >
              {noleggio?.id_stato != 'IB' && (
                <Button
                  variant="contained"
                  color="white"
                  sx={{ width: '200px' }}
                  size="small"
                  onClick={onSalvaRichiesta}
                  startIcon={<Edit />}
                >
                  SALVA PROPOSTA
                  {noleggio.id_stato == 'IB' && ' IN BOZZA'}
                </Button>
              )}

              {noleggio.id_stato == 'DC' && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: '200px' }}
                  onClick={onInviaProposta}
                  startIcon={<Send />}
                >
                  INOLTRA
                </Button>
              )}

              <IconButton
                size="small"
                onClick={onVisualizzaRiepilogo}
                variant="contained"
                color="primary"
              >
                <Print />
              </IconButton>

              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>

            {noleggio.id_stato == 'DC' && (
              <Box sx={{ mb: 2 }}>
                <ImportoCanoneMobile
                  importo={noleggio.importo}
                  dettagli={noleggio.dettagli ?? []}
                  mesi={noleggio?.durata_mesi}
                  assicurazione={noleggio?.flg_assicurazione}
                  backgroundColor="white"
                  compactMode={false}
                />
              </Box>
            )}
          </Box>
        )}

        <Stack sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <Box>
            <Box>
              {matchXl ? (
                <IntestazioneNoleggio
                  noleggio={noleggio}
                  setNoleggio={setNoleggio}
                  compactViewMode={noleggio.id_stato == 'DC'}
                  onSalvaRichiesta={onSalvaRichiesta}
                  onScaricaProposta={onScaricaProposta}
                />
              ) : (
                <IntestazioneNoleggioVerticale
                  noleggio={noleggio}
                  setNoleggio={setNoleggio}
                  compactViewMode={noleggio.id_stato == 'DC'}
                  importoRow={matchMd ? true : false}
                />
              )}
            </Box>

            {noleggio.id_stato != 'DC' ? (
              <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  <Typography component="h4" variant="h6">
                    Vuoi completare la Proposta di Noleggio?
                  </Typography>
                  <Typography variant="body1">
                    Per inoltrare una proposta di noleggio devi inserire i dati
                    richiesti
                  </Typography>
                  {matchMd ? (
                    <Button
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={onCompletaRichiesta}
                    >
                      COMPLETA PROPOSTA DI NOLEGGIO
                    </Button>
                  ) : (
                    <Stack alignItems={'center'}>
                      <Button
                        variant="contained"
                        color="white"
                        sx={{ mt: 2, width: '300px' }}
                        size="small"
                        onClick={onSalvaRichiesta}
                        startIcon={<Edit />}
                      >
                        SALVA PROPOSTA
                        {noleggio.id_stato == 'IB' && ' IN BOZZA'}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ mt: 2, width: '300px' }}
                        onClick={onCompletaRichiesta}
                      >
                        COMPLETA PROPOSTA DI NOLEGGIO
                      </Button>
                    </Stack>
                  )}
                </Box>
              </Stack>
            ) : (
              <></>
            )}

            {getCompilaDati()}
          </Box>
        </Stack>
      </Stack>
    </>
  );
}
