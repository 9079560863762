import { Stack, Modal, Typography, IconButton, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import ChatImg from '../../../assets/img/Chat.png';
import { Close } from '@mui/icons-material';

dayjs.locale('it');

export default function ModalComunicazione({
  showModalComunicazione,
  setShowModalComunicazione,
  itemModalComunicazione,
  setItemModalComunicazione,
}) {
  const [formatDataItem, setFormatDataItem] = useState(null);

  const formatData = () => {
    let data = dayjs(itemModalComunicazione.created_at);
    let dataFormattata = data.format('dddd, DD/MM/YY');

    return {
      ...itemModalComunicazione,
      data: dataFormattata.charAt(0).toUpperCase() + dataFormattata.slice(1),
    };
  };

  const onClose = () => {
    setItemModalComunicazione(null);
    setShowModalComunicazione(false);
  };

  useEffect(() => {
    if (itemModalComunicazione) setFormatDataItem(formatData());
  }, [itemModalComunicazione]);
  return (
    <Modal open={showModalComunicazione} onClose={onClose}>
      <Box
        sx={{
          minWidth: '50%',
          maxWidth: '50%',
          backgroundColor: 'white',
          position: 'absolute',
          top: '30%',
          left: '30%',
          boxShadow: 24,
          minHeight: 300,
          p: 3,
        }}
      >
        {formatDataItem && (
          <Stack flexDirection="row">
            <Stack>
              <img src={ChatImg} alt="logo" width={32} />
            </Stack>
            <Stack sx={{ pl: 3, pr: 3, flex: 1 }}>
              <Typography>{formatDataItem.data}</Typography>
              <Typography sx={{ mt: 1, mb: 2 }} component="h2" variant="h5">
                {formatDataItem.titolo}
              </Typography>
              <Typography>
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatDataItem.testo,
                  }}
                ></span>
              </Typography>
            </Stack>
            <Stack>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        )}
      </Box>
    </Modal>
  );
}
