import { Box, Stack } from '@mui/material';
import { useState } from 'react';

export default function Slider({ children }) {
  const [indexReport, setIndexReport] = useState(0);

  return (
    <Box>
      <Stack sx={{ flex: 1, position: 'relative', minHeight: 250 }}>
        {children[indexReport]}
      </Stack>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children.map((child, index) => (
          <Box
            key={index}
            onClick={() => setIndexReport(index)}
            sx={{
              height: 15,
              width: 15,
              backgroundColor: indexReport == index ? '#3A7FDF' : '#C6C6C6',
              borderRadius: 100,
              '&:hover': {
                backgroundColor: 'primary.dark',
                cursor: 'pointer',
              },
            }}
          />
        ))}
      </Stack>
    </Box>
  );
}
