import { createSlice } from '@reduxjs/toolkit';
import { getProduttori } from './operations';
import {
  createAsyncState,
  handlePending,
  handleFulfilled,
  handleRejected,
} from '../base';

const initialState = {
  produttori: [],
  getProduttoriAction: createAsyncState(),
};

const produttoriSlice = createSlice({
  name: 'produttori',
  initialState,

  extraReducers: builder => {
    builder
      .addCase(getProduttori.pending, state => {
        handlePending(state.getProduttoriAction);
      })
      .addCase(getProduttori.fulfilled, (state, action) => {
        state.produttori = action.payload;
        handleFulfilled(state.getProduttoriAction, action);
      })
      .addCase(getProduttori.rejected, (state, action) => {
        handleRejected(state.getProduttoriAction, action);
      });
  },
});

export const produttoriReducer = produttoriSlice.reducer;
