import { useState } from 'react';
import {
  Typography,
  Box,
  Stack,
  useTheme,
  IconButton,
  Alert,
} from '@mui/material';
import { Clear, FileUpload } from '@mui/icons-material';
import Dropzone from 'react-dropzone';

const supportedFormatsMaps = {
  pdf: {
    type: 'application/pdf',
    extensions: ['.pdf'],
    label: 'PDF',
  },
  image: {
    type: 'image/*',
    extensions: ['.jpg', '.jpeg', '.png'],
    label: 'Immagini',
  },
};

const CaricaFile = ({
  isPresent,
  onLoad,
  onCancel,
  acceptedFormats = ['pdf'],
  alreadyPresentMessage = null,
}) => {
  const theme = useTheme();
  const [file, setFile] = useState(null);

  const onDrop = acceptedFiles => {
    setFile(acceptedFiles[0]);
    onLoad(acceptedFiles[0]);
  };

  const onCancelFile = () => {
    setFile(null);
    onCancel();
  };

  const acceptedFormatsMaps = {};
  acceptedFormats.forEach(format => {
    acceptedFormatsMaps[supportedFormatsMaps[format].type] =
      supportedFormatsMaps[format].extensions;
  });

  let acceptedFormatsLabel = '';
  acceptedFormats.forEach(format => {
    acceptedFormatsLabel += `${supportedFormatsMaps[format].label}, `;
  });
  if (acceptedFormatsLabel.length > 0) {
    acceptedFormatsLabel = acceptedFormatsLabel.slice(0, -2);
  }

  return (
    <Box>
      {isPresent && alreadyPresentMessage ? (
        <Alert severity="info" sx={{ mb: 2 }}>
          <Typography variant="body1">{alreadyPresentMessage}</Typography>
        </Alert>
      ) : (
        <></>
      )}
      <Dropzone onDrop={onDrop} accept={acceptedFormatsMaps}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps({
                className: 'dropzone',
                style: {
                  border: `2px dashed ${theme.palette.primary.main}`,
                  borderRadius: '8px',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                },
              })}
            >
              <input {...getInputProps()} />
              <Box>
                <FileUpload sx={{ color: theme.palette.primary.main }} />
                <Typography variant="subtitle2">
                  Trascina o scegli il file da caricare
                </Typography>
                <Typography variant="caption">
                  {acceptedFormatsLabel}
                </Typography>
              </Box>
            </div>
            {file && (
              <Stack
                flexDirection="row"
                spacing={2}
                sx={{ mt: 2, alignItems: 'center' }}
              >
                <Typography variant="subtitle2">{file.name}</Typography>
                <IconButton
                  variant="outlined"
                  onClick={onCancelFile}
                  size="small"
                  sx={{ color: theme.palette.error.main }}
                  style={{
                    marginTop: '0px',
                    marginLeft: '8px',
                    padding: '0px',
                  }}
                >
                  <Clear />
                </IconButton>
              </Stack>
            )}
          </section>
        )}
      </Dropzone>
    </Box>
  );
};

export default CaricaFile;
