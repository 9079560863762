import instance from './axiosConfig';

const setUpInterceptor = store => {
  const handleError = async error => {
    return Promise.reject(error);
  };

  instance.interceptors.request.use(async config => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers
        ? (config.headers.Authorization = `Bearer ${token}`)
        : (config.headers = config.headers || `Bearer ${token}`);
    }
    return config;
  });

  instance.interceptors.response.use(
    response => response,
    async error => {
      if (error.response.status === 401) {
        store.dispatch({ type: 'auth/logout' });
      }
      return handleError(error);
    }
  );
};

export default setUpInterceptor;
