import { Card, Stack, Typography, Grid2 as Grid } from '@mui/material';
import LoadingMask from '../../../components/LoadingMask';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import TotaleComponent from './TotaleComponent';
import GraficoTorta from './GraficoTorta';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/it';
import GraficoBarreOrizzontale from './GraficoBarreOrizzontale';
import IntestazioneGrafici from './IntestazioneGrafici';
import useInoltriData from '../hook/useInoltriData';

dayjs.locale('it');

export default function InoltriPanel() {
  const {
    isLoadingInoltriData,
    inoltriData,
    loadingErrorInoltriData,
    dataInizioInoltri,
    setDataInizioInoltri,
    dataFineInoltri,
    setDataFineInoltri,
  } = useInoltriData();

  return (
    <Stack sx={{ marginTop: 5, position: 'relative' }}>
      <Card sx={{ backgroundColor: '#FAFAFA' }}>
        <Typography component="h3" variant="h5" sx={{ color: '#284BAA' }}>
          Pratiche Inoltrate
        </Typography>
        <Stack sx={{ marginTop: 3 }} flexDirection="row">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <DatePicker
              label="Data Inizio"
              value={dataInizioInoltri}
              format="DD/MM/YYYY"
              onChange={val => setDataInizioInoltri(val)}
              sx={{ marginRight: 3 }}
            />
            <DatePicker
              label="Data Fine"
              value={dataFineInoltri}
              format="DD/MM/YYYY"
              sx={{
                marginLeft: '18px',
              }}
              onChange={val => setDataFineInoltri(val)}
            />
          </LocalizationProvider>
        </Stack>
        {inoltriData ? (
          <Stack>
            <Stack
              flexDirection="row"
              sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}
            >
              <TotaleComponent
                titolo={'Tot. Pratiche Inoltrate'}
                value={inoltriData.counter}
              />
              <Stack sx={{ flex: 1 }}></Stack>
            </Stack>
            <Grid container spacing={2} columns={12}>
              <Grid size={{ xs: 12, md: 6 }}>
                <IntestazioneGrafici
                  titolo={'Esito Pratiche Inoltrate'}
                  sottotitolo={"Confronta l'esito delle pratiche inoltrate"}
                />
                <GraficoTorta
                  labelPos="bottom"
                  value={inoltriData.confermate_declinate}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <IntestazioneGrafici
                  titolo={'Status Pratiche Inoltrate'}
                  sottotitolo={
                    'Visiona in realtime lo status delle pratiche attualmente inoltrate'
                  }
                />
                <GraficoBarreOrizzontale value={inoltriData.stati} />
              </Grid>
            </Grid>
          </Stack>
        ) : (
          <></>
        )}
      </Card>
      {loadingErrorInoltriData != null && (
        <ActionErrorMessage error={loadingErrorInoltriData} />
      )}
      {isLoadingInoltriData ? <LoadingMask /> : <></>}
    </Stack>
  );
}
