import {
  Box,
  Button,
  Card,
  CardContent,
  Slider,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { Add, Edit, Remove } from '@mui/icons-material';
import DecimalInputDecorated from '../../../components/DecimalInputDecorated';
import ImportoCanoneHome from './ImportoCanoneHome';

const sliderMesi = [
  {
    label: 24,
    value: 24,
  },
  {
    label: 36,
    value: 36,
  },
  {
    label: 48,
    value: 48,
  },
  {
    label: 60,
    value: 60,
  },
  {
    label: 72,
    value: 72,
  },
];

function IntestazioneNoleggioVerticale({
  noleggio,
  setNoleggio,
  showButtons = false,
  compactViewMode = false,
  showTitle = true,
  importoRow = true,
  assicurazioneRow = true,
  fullHeight = false,
}) {
  const theme = useTheme();
  const handleInputChange = e => {
    setNoleggio(prev => ({
      ...prev,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }));
  };

  const TitleWithHelper = ({ title1, title2, subtitle }) => {
    return (
      <Box>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins-SemiBold' }}>
            {title1}{' '}
            <span style={{ color: theme.palette.primary.main }}>{title2}</span>{' '}
          </Typography>
        </Stack>
        <Typography variant="caption" sx={{ lineHeight: '0px' }}>
          {subtitle}
        </Typography>
      </Box>
    );
  };

  const Item = ({ title, component, direction }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: direction,
          justifyContent: direction === 'row' ? 'space-between' : 'start',
          alignItems: direction === 'row' ? 'center' : 'start',
          mb: 4,
        }}
      >
        <Box sx={{ minWidth: '300px', display: 'block' }}>{title}</Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            alignContent={'center'}
          >
            {component}
          </Stack>
        </Box>
      </Box>
    );
  };

  return (
    <Stack
      sx={{
        position: 'relative',
        alignContent: 'center',
        display: 'flex',
        height: fullHeight ? '100%' : 'auto',
      }}
    >
      {/* <Card
        sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 10,
          height: 10,
          bgcolor: 'background.darkest',
          color: 'white',
          zIndex: 1,
          top: '40px',
        }}
      >
        <img src={CalcoloRataSvg} alt="Calcolo Rata" />
      </Card> */}
      <Card
        sx={{
          border: 'none',
          m: 0,
          backgroundColor: 'background.primaryLight',
          height: fullHeight ? '100%' : 'auto',
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Stack flexDirection="row" justifyContent="space-between">
            {showTitle && (
              <Typography variant="h6" sx={{ mb: 2 }}>
                Preventivatore
              </Typography>
            )}
          </Stack>

          <Box sx={{ flex: 2, p: 2 }}>
            {!compactViewMode ? (
              <Item
                title={
                  <TitleWithHelper
                    title1="Inserisci"
                    title2="Importo Richiesto (€)"
                    subtitle="Inserisci l'importo richiesto per il noleggio"
                  />
                }
                direction={importoRow ? 'row' : 'column'}
                component={
                  <>
                    <DecimalInputDecorated
                      format={'EUR'}
                      value={noleggio?.importo}
                      onChange={value => {
                        handleInputChange({
                          target: {
                            name: 'importo',
                            value: parseFloat(value),
                          },
                        });
                      }}
                      LeftButtonIcon={Remove}
                      RightButtonIcon={Add}
                      minWidth="120px"
                      step={1}
                      round={0.01}
                    />
                  </>
                }
              />
            ) : (
              <></>
            )}

            <Item
              title={
                <TitleWithHelper
                  title1="Inserisci"
                  title2="Durata (mesi)"
                  subtitle="Inserisci la durata richiesta dal noleggio"
                />
              }
              direction="column"
              component={
                <Slider
                  sx={{ maxWidth: '400px', my: 2 }}
                  name="durata_mesi"
                  aria-label="Custom marks"
                  defaultValue={24}
                  min={24}
                  max={72}
                  step={12}
                  valueLabelDisplay="auto"
                  marks={sliderMesi}
                  value={noleggio?.durata_mesi}
                  getAriaValueText={value => `${value} mesi`}
                  onChange={(e, value) => {
                    handleInputChange({
                      target: {
                        name: 'durata_mesi',
                        value: parseInt(value),
                      },
                    });
                  }}
                />
              }
            />
            <Item
              title={
                <TitleWithHelper
                  title1="Vuoi aggiungere il"
                  title2="Servizio Assicurazione?"
                  subtitle="Doinn offre il servizio assicurazione a tutti i beni noleggiati aggiungendo € 1.00 al mese"
                />
              }
              direction={assicurazioneRow ? 'row' : 'column'}
              component={
                <Switch
                  checked={noleggio?.flg_assicurazione == 1 ? true : false}
                  name="flg_assicurazione"
                  onChange={(e, checked) => {
                    handleInputChange({
                      target: {
                        name: 'flg_assicurazione',
                        type: 'checkbox',
                        checked,
                      },
                    });
                  }}
                />
              }
            />

            {!compactViewMode ? (
              <ImportoCanoneHome
                importo={noleggio?.importo}
                mesi={noleggio?.durata_mesi}
                assicurazione={noleggio?.flg_assicurazione}
                backgroundColor="white"
                compactMode={false}
              />
            ) : (
              <></>
            )}

            {showButtons && noleggio?.importo > 0 ? (
              <>
                <Stack
                  flexDirection={'row'}
                  gap={2}
                  justifyContent={'center'}
                  alignContent={'center'}
                  alignItems={'center'}
                  sx={{ backgroundColor: 'background.primaryLight', mt: 4 }}
                >
                  <Button
                    variant="contained"
                    color="white"
                    startIcon={<Edit />}
                    size="small"
                  >
                    SALVA PROPOSTA IN BOZZA
                  </Button>
                  <Button variant="contained" color="primary" size="small">
                    COMPLETA PROPOSTA NOLEGGIO
                  </Button>
                </Stack>
              </>
            ) : (
              <> </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default IntestazioneNoleggioVerticale;
