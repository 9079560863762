import {
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingMask from '../../../components/LoadingMask';
import { ArrowBackIos, Check, Close, Save } from '@mui/icons-material';
import useProduttoreCard from '../hook/useProduttoreCard';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/app/slice';
import SelectProvince from '../../Noleggi/components/SelectProvince';
import { nuovoProduttore } from '../../../redux/produttori/api';

export default function ProduttoreCard({
  isNewProduttore,
  produttoreData,
  onSaved,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { formData, setFormData, handleInputChange, errors, handleSubmit } =
    useProduttoreCard(produttoreData);

  const onProduttoreLoaded = produttore => {
    setFormData(produttore);
  };

  const onNuovoProduttore = async () => {
    onSalva(async () => {
      await nuovoProduttore(formData);
    });
  };

  const onSalva = async fn => {
    const validation = handleSubmit();
    if (validation === -1) {
      return;
    }

    setIsLoading(true);
    try {
      const result = await fn();
      onProduttoreLoaded(result);
      onSaved();
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {isLoading && <LoadingMask />}
      <Stack
        justifyContent="space-between"
        flexDirection="row"
        sx={{
          backgroundColor: 'background.primaryLight',
          p: 2,
          borderRadius: '4px',
        }}
      >
        <Stack flexDirection="row" alignItems="center" sx={{ flex: 1 }}>
          <IconButton
            sx={{ border: 'none' }}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIos />
          </IconButton>
          <Box sx={{ ml: 1 }}>
            <Typography component="h4" variant="body">
              Gestione Produttore
            </Typography>
            <Stack flexDirection="row">
              <Typography component="h3" variant="body">
                {isNewProduttore
                  ? 'Crea Nuovo Produttore'
                  : 'Visualizza Produttore'}
              </Typography>
            </Stack>
          </Box>
          {!isNewProduttore ? (
            <Stack
              flexDirection="row"
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ flex: 1 }}
            >
              {formData.flg_verificato ? (
                <>
                  <Check sx={{ color: 'green', mr: 3 }} />
                  <Typography component="h4" variant="body">
                    Il Produttore è stato correttamente verificato dal
                    Noleggiatore.
                  </Typography>
                </>
              ) : (
                <>
                  <Close sx={{ color: 'red', mr: 3 }} />
                  <Typography component="h4" variant="body">
                    Il Produttore non è ancora stato verificato dal
                    Noleggiatore.
                  </Typography>
                </>
              )}
            </Stack>
          ) : (
            <></>
          )}
        </Stack>

        {isNewProduttore ? (
          <Button
            size="small"
            variant="contained"
            startIcon={<Save />}
            onClick={onNuovoProduttore}
          >
            CREA PRODUTTORE
          </Button>
        ) : (
          <></>
        )}
      </Stack>

      <Card sx={{ backgroundColor: 'background.default' }}>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="subtitle2"
            sx={{ fontFamily: 'Poppins-SemiBold' }}
          >
            Dati Produttore
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="ragione_sociale">Ragione Sociale</FormLabel>
              <TextField
                placeholder="Ragione Sociale"
                name="ragione_sociale"
                variant="outlined"
                value={formData?.ragione_sociale}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.ragione_sociale}
                helperText={errors.ragione_sociale}
                slotProps={{
                  input: {
                    readOnly: !isNewProduttore ? true : false,
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="cf">Codice Fiscale</FormLabel>
              <TextField
                placeholder="Codice Fiscale"
                name="cf"
                variant="outlined"
                value={formData?.cf}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.cf}
                helperText={errors.cf}
                slotProps={{
                  input: {
                    readOnly: !isNewProduttore ? true : false,
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="piva">Partita IVA</FormLabel>
              <TextField
                placeholder="Partita IVA"
                name="piva"
                variant="outlined"
                value={formData?.piva}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.piva}
                helperText={errors.piva}
                slotProps={{
                  input: {
                    readOnly: !isNewProduttore ? true : false,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, mb: 2 }}>
          <Typography variant="subtitle2">Indirizzo:</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 3 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="sede_indirizzo">Indirizzo Sede</FormLabel>
              <TextField
                placeholder="Indirizzo Sede"
                name="sede_indirizzo"
                value={formData?.sede_indirizzo}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.sede_indirizzo}
                helperText={errors.sede_indirizzo}
                slotProps={{
                  input: {
                    readOnly: !isNewProduttore ? true : false,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 6, sm: 2 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="sede_civico">N. Civico</FormLabel>
              <TextField
                placeholder="N. Civico"
                name="sede_civico"
                value={formData?.sede_civico}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.sede_civico}
                helperText={errors.sede_civico}
                slotProps={{
                  input: {
                    readOnly: !isNewProduttore ? true : false,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 6, sm: 2 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="sede_cap">CAP</FormLabel>
              <TextField
                placeholder="CAP"
                name="sede_cap"
                value={formData?.sede_cap}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.sede_cap}
                helperText={errors.sede_cap}
                slotProps={{
                  input: {
                    readOnly: !isNewProduttore ? true : false,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="sede_citta">Città</FormLabel>
              <TextField
                placeholder="Città"
                name="sede_citta"
                value={formData?.sede_citta}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.sede_citta}
                helperText={errors.sede_citta}
                slotProps={{
                  input: {
                    readOnly: !isNewProduttore ? true : false,
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, sm: 3 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="sede_provincia">Provincia</FormLabel>
              <SelectProvince
                name="sede_provincia"
                placeholder="Provincia"
                value={formData?.sede_provincia}
                onChange={handleInputChange}
                errors={errors.sede_provincia}
                readOnly={!isNewProduttore ? true : false}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
