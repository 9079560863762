import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { formatNumber } from '../../../utils/formatNumber';

export default function HorizontalBarChartCustom({ data, format, symbol }) {
  const [chartData, setChartData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (data) {
      setChartData(formatChartData(data));
    } else {
      setChartData([]);
    }
  }, [data]);

  const formatChartData = data => {
    let tmpMaxVal = 0;
    let formatData = Object.values(data).map(item => {
      if (item.counter > tmpMaxVal) {
        tmpMaxVal = item.counter;
      }
      return {
        value: item.counter,
        label: item.nome,
      };
    });

    setMaxValue(tmpMaxVal);
    return formatData;
  };

  return (
    <Stack
      // spacing={2}
      justifyContent={'center'}
      sx={{
        overflowY: 'auto',
        // maxHeight: 1000,
        flex: 1,
      }}
    >
      {chartData &&
        chartData.map((item, _index) => {
          const widthPercent = (item.value / maxValue) * 100;
          return (
            <Stack
              key={_index}
              flexDirection="row"
              justifyContent={'start'}
              alignItems={'center'}
              sx={{ mb: 1 }}
            >
              <Typography sx={{ flex: 1 }}>{item.label}</Typography>
              <Box sx={{ flex: 4, mr: 1 }}>
                <LinearProgress
                  sx={{
                    height: 20,
                    borderRadius: 0,
                  }}
                  variant="determinate"
                  value={widthPercent}
                />
              </Box>
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  position: 'relative',
                  borderRadius: 1,
                  maxWidth: 100,
                  backgroundColor: 'black',
                  marginLeft: 3,
                  height: 30,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <Box
                  sx={{
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    borderTop: '10px solid transparent',
                    borderBottom: '10px solid transparent',
                    borderRight: '10px solid black',
                    position: 'absolute',
                    top: 5,
                    left: -5,
                  }}
                ></Box>
                <Typography variant="body1" sx={{ color: 'white' }}>
                  {format ? formatNumber(item.value, symbol) : item.value}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
    </Stack>
  );
}
