import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlertDialog } from '../redux/app/slice';
import { Close } from '@mui/icons-material';

const AlertDialog = () => {
  const dispatch = useDispatch();
  const { alertDialogConfig } = useSelector(state => state.app);

  const handleClose = () => {
    dispatch(hideAlertDialog());
  };

  return alertDialogConfig != null ? (
    <Dialog open={alertDialogConfig.open} onClose={handleClose} fullWidth>
      <Box sx={{ backgroundColor: 'background.default', p: 2 }}>
        <DialogContent>
          <Stack
            sx={{ position: 'absolute', top: 5, right: 5 }}
            direction="row"
            spacing={2}
          >
            <IconButton size="small" onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Stack justifyContent={'center'} alignItems="center" spacing={0}>
            <Typography
              variant="body1"
              fontWeight={'bold'}
              sx={{ textAlign: 'center' }}
            >
              {alertDialogConfig.title}
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              {alertDialogConfig.message}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
          {alertDialogConfig.btnAnnullaText != null && (
            <Button
              onClick={() => {
                if (alertDialogConfig.onCancel) {
                  alertDialogConfig.onCancel();
                }

                handleClose();
              }}
              variant="outlined"
              size="small"
              sx={{ minWidth: '150px' }}
            >
              {alertDialogConfig.btnAnnullaText ?? 'Annulla'}
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            sx={{ minWidth: '150px' }}
            onClick={() => {
              if (alertDialogConfig.onConfirm) {
                alertDialogConfig.onConfirm();
              }

              handleClose();
            }}
          >
            {alertDialogConfig.btnConfermaText ?? 'Conferma'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  ) : null;
};

export default AlertDialog;
