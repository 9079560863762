import { useEffect, useState } from 'react';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { getListaFornitori } from '../../../redux/noleggi/api';

const SelectFornitore = ({ name, placeholder, value, onChange, errors }) => {
  const [listaFornitori, setListaFornitori] = useState([]);

  useEffect(() => {
    const loadFornitori = async () => {
      let response = await getListaFornitori();
      setListaFornitori(response);
    };

    if (listaFornitori.length == 0) {
      loadFornitori();
    }
  }, []);

  const _onChange = e => {
    onChange({
      target: {
        name: name,
        value: e.target.value,
        ragione_sociale: listaFornitori.find(
          fornitore => fornitore.id == e.target.value
        ).ragione_sociale,
      },
    });
  };

  return (
    <>
      <Select
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={_onChange}
        sx={{ flex: 1 }}
      >
        {listaFornitori?.map(fornitore => (
          <MenuItem key={fornitore.id} value={fornitore.id}>
            {fornitore.ragione_sociale}
          </MenuItem>
        ))}
      </Select>
      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </>
  );
};

export default SelectFornitore;
