import { useEffect } from 'react';

const useResizer = ref => {
  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();

      const visibileHeight = window.innerHeight - rect.top - 8; //8 sono il padding e margin del Layout
      ref.current.style.height = `${visibileHeight}px`;
    }
  }, []);

  //on window resize
  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const visibileHeight = window.innerHeight - rect.top - 8; //8 sono il padding e margin del Layout
        ref.current.style.height = `${visibileHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);
};

export default useResizer;
