import { createTheme } from '@mui/material/styles';
import getCustomTheme from './theme/getCustomTheme';
import { itIT } from '@mui/material/locale';
import { itIT as itITDataGrid } from '@mui/x-data-grid/locales';
import { itIT as itITDatePicker } from '@mui/x-date-pickers/locales';

// A custom theme for this app
const theme = createTheme(
  getCustomTheme('light'),
  itIT,
  itITDataGrid,
  itITDatePicker
);

export default theme;
