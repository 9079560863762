import {
  Box,
  Card,
  CardContent,
  Divider,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import ImportoCanone from './ImportoCanone';
import DecimalInputDecorated from '../../../components/DecimalInputDecorated';

const sliderMesi = [
  {
    label: 24,
    value: 24,
  },
  {
    label: 36,
    value: 36,
  },
  {
    label: 48,
    value: 48,
  },
  {
    label: 60,
    value: 60,
  },
  {
    label: 72,
    value: 72,
  },
];

function IntestazioneNoleggioDashboard({ noleggio, setNoleggio }) {
  const handleInputChange = e => {
    setNoleggio(prev => ({
      ...prev,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }));
  };

  const TitleWithHelper = ({ title1, title2, subtitle }) => {
    return (
      <Box>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body1">{title1} </Typography>
          <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
            {title2}
          </Typography>
          :
        </Stack>
        <Typography variant="caption" sx={{ lineHeight: '0px' }}>
          {subtitle}
        </Typography>
      </Box>
    );
  };

  const Item = ({ title, component }) => {
    return (
      <Stack justifyContent="start" alignItems="start" sx={{ p: 1 }}>
        <Box>{title}</Box>
        <Box sx={{ mt: 1 }}>{component}</Box>
      </Stack>
    );
  };

  return (
    <Card
      sx={{
        border: 'none',
        backgroundColor: 'background.primaryLight',
      }}
    >
      <CardContent>
        <Stack gap={5} flexDirection="row">
          <Box sx={{ flex: 1 }}>
            <Item
              title={
                <TitleWithHelper
                  title1="Inserisci"
                  title2="Importo Richiesto (€)"
                  subtitle="Inserisci l'importo richiesto per il noleggio"
                />
              }
              component={
                <DecimalInputDecorated
                  step={1}
                  round={0.01}
                  format={'EUR'}
                  name="importo"
                  value={noleggio?.importo}
                  onChange={value => {
                    handleInputChange({
                      target: {
                        name: 'importo',
                        value: parseFloat(value),
                      },
                    });
                  }}
                  minWidth="120px"
                />
              }
            />

            <Item
              title={
                <TitleWithHelper
                  title1="Inserisci"
                  title2="Durata (mesi)"
                  subtitle="Inserisci la durata richiesta dal noleggio"
                />
              }
              component={
                <Slider
                  sx={{ width: '300px' }}
                  name="durata_mesi"
                  aria-label="Custom marks"
                  defaultValue={24}
                  min={24}
                  max={72}
                  step={12}
                  valueLabelDisplay="auto"
                  marks={sliderMesi}
                  value={noleggio?.durata_mesi}
                  getAriaValueText={value => `${value} mesi`}
                  onChange={(e, value) => {
                    handleInputChange({
                      target: {
                        name: 'durata_mesi',
                        value: parseInt(value),
                      },
                    });
                  }}
                />
              }
            />
          </Box>

          <Divider orientation="vertical" flexItem />
          <Stack
            sx={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ImportoCanone
              importo={noleggio?.importo}
              mesi={noleggio?.durata_mesi}
              assicurazione={noleggio?.flg_assicurazione}
              backgroundColor="transparent"
              compactMode={false}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default IntestazioneNoleggioDashboard;
