import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Stack } from '@mui/material';
import { ArrowForwardIos, Download } from '@mui/icons-material';
import { getDocumenti } from '../../../redux/noleggi/operations';
import { formatStringDate } from '../../../utils/formadDate';
import useAuth from '../../../hooks/useAuth';

export default function DocumentiTable({ onOpenRichiesta, onDownload }) {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const { documenti, getDocumentiAction } = useSelector(state => state.noleggi);
  useEffect(() => {
    dispatch(getDocumenti());
  }, []);

  const getColumnAzienda = () => {
    if (user.tipologia === 'F')
      return { field: 'azienda', headerName: 'Azienda', flex: 1 };

    return { field: 'fornitore', headerName: 'Fornitore', flex: 1 };
  };

  const columns = [
    {
      field: 'data',
      headerName: 'Data',
      flex: 1,
      valueFormatter: value => {
        return formatStringDate(value);
      },
    },
    {
      field: 'nome',
      headerName: 'Documento',
      flex: 1,
    },
    { field: 'codice', headerName: 'Operazione Associata', flex: 1 },
    getColumnAzienda(),
    {
      field: 'azioni',
      headerName: 'Azioni',
      sortable: false,
      headerClassName: 'no-separator',
      cellClassName: 'no-separator',
      disableColumnMenu: true,
      renderCell: params => (
        <Stack
          flexDirection="row"
          gap={1}
          sx={{
            justifyContent: 'start',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
            onClick={() => onDownload(params.row)}
          >
            <Download />
          </IconButton>
          <IconButton
            size="small"
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
            onClick={() => onOpenRichiesta(params.row)}
          >
            <ArrowForwardIos />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ md: 12, lg: 12 }} sx={{ width: '100%' }}>
        <DataGrid
          onRowDoubleClick={params => onOpenRichiesta(params.row)}
          autoHeight
          rows={documenti}
          columns={columns}
          loading={getDocumentiAction.loading}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          pageSizeOptions={[10, 20, 50]}
          disableColumnResize
          density="standard"
        />
      </Grid>
    </Grid>
  );
}
