import { z } from 'zod';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import useZod from '../../../hooks/useZod';
import { useState } from 'react';
import { setNewPassword } from '../../../redux/auth/api';
import { setUser } from '../../../redux/auth/slice';
import ActionErrorMessage from '../../../components/ActionErrorMessage';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const formSchema = z
  .object({
    password: z.string().min(8, { message: 'Password troppo corta' }),
    password_confirm: z.string().min(8, { message: 'Password troppo corta' }),
  })
  .refine(data => data.password === data.password_confirm, {
    message: 'Le password non corrispondono',
    path: ['password_confirm'],
  });

export default function SetNewPasswordCard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { errors, validate, handleInputChange } = useZod({
    schema: formSchema,
    initialFormData: {
      password: '',
      password_confirm: '',
    },
  });

  const handleSubmit = async () => {
    const validation = validate();
    if (validation.success) {
      setIsLoading(true);
      try {
        const result = await setNewPassword(validation.data);
        dispatch(setUser(result));
        navigate('/dashboard');
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Card variant="outlined">
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
      >
        Imposta una nuova password
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <TextField
            error={!!errors.password}
            helperText={errors.password}
            onChange={handleInputChange}
            name="password"
            placeholder="••••••"
            type="password"
            autoComplete="current-password"
            autoFocus
            fullWidth
            variant="outlined"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password_confirm">Conferma Password</FormLabel>
          <TextField
            error={!!errors.password_confirm}
            helperText={errors.password_confirm}
            onChange={handleInputChange}
            name="password_confirm"
            placeholder="••••••"
            type="password"
            autoComplete="current-password"
            fullWidth
            variant="outlined"
          />
        </FormControl>

        <LoadingButton
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          loading={isLoading}
        >
          Aggiorna Password
        </LoadingButton>
        <ActionErrorMessage error={error} />
      </Box>
    </Card>
  );
}
