import { useState } from 'react';

const useZod = ({ schema, initialFormData }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const handleInputChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validate = () => {
    setErrors({});
    const result = schema.safeParse(formData ?? {});

    if (!result.success) {
      const formattedErrors = result.error.errors.reduce((acc, err) => {
        acc[err.path[0]] = err.message;
        return acc;
      }, {});
      setErrors(formattedErrors);
      return { success: false };
    }

    return { success: true, data: result.data };
  };

  const resetErrors = () => {
    setErrors({});
  };

  return {
    handleInputChange,
    validate,
    setFormData,
    formData,
    errors,
    resetErrors,
  };
};

export default useZod;
