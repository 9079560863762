import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProduttori } from '../../../redux/produttori/operations';
import { IconButton, Stack } from '@mui/material';
import { ArrowForwardIos, Check } from '@mui/icons-material';

export default function ProduttoriTable({ onEdit }) {
  const dispatch = useDispatch();

  const { produttori, getProduttoriAction } = useSelector(
    state => state.produttori
  );
  useEffect(() => {
    dispatch(getProduttori());
  }, []);

  const columns = [
    {
      field: 'ragione_sociale',
      headerName: 'Ragione Sociale',
      flex: 1,
    },
    {
      field: 'piva',
      headerName: 'P.IVA',
      flex: 1,
    },
    { field: 'cf', headerName: 'Codice Fiscale', flex: 1 },
    {
      field: 'flg_verificato',
      headerName: 'Verificato',
      renderCell: params => {
        return (
          <Stack
            sx={{
              justifyContent: 'center',
              height: '100%',
              alignItems: 'center',
            }}
          >
            {params.row.flg_verificato ? <Check /> : <></>}
          </Stack>
        );
      },
    },
    {
      field: 'azioni',
      headerName: 'Azioni',
      sortable: false,
      headerClassName: 'no-separator',
      cellClassName: 'no-separator',
      disableColumnMenu: true,
      renderCell: params => (
        <Stack
          flexDirection="row"
          gap={1}
          sx={{
            justifyContent: 'start',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
            onClick={() => onEdit(params.row)}
          >
            <ArrowForwardIos />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ md: 12, lg: 12 }} sx={{ width: '100%' }}>
        <DataGrid
          onRowDoubleClick={params => onEdit(params.row)}
          autoHeight
          rows={produttori}
          columns={columns}
          loading={getProduttoriAction.loading}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          pageSizeOptions={[10, 20, 50]}
          disableColumnResize
          density="standard"
        />
      </Grid>
    </Grid>
  );
}
