import api from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleAsyncThunk } from '../asyncWrapper';

/*
 * GET @ preventivatore/notifiche
 * response: [notifiche]
 */
export const getNotifiche = createAsyncThunk(
  'notifiche/getNotifiche',
  handleAsyncThunk(async () => {
    const res = await api.get('/preventivatore/notifiche');
    return res.data.data;
  })
);
