import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useAuth from '../hooks/useAuth';
import { ReactComponent as OpAttiveSvg } from '../assets/img/OpAttive.svg';
import { ReactComponent as OpNonAttiveSvg } from '../assets/img/OpNonAttive.svg';
import { ReactComponent as RichiestaModificaMenuSvg } from '../assets/img/RichiestaModificaMenu.svg';
import { ReactComponent as ReportSvg } from '../assets/img/Report.svg';
import { ReactComponent as SupportoSvg } from '../assets/img/Supporto.svg';
import { ReactComponent as DashboardSvg } from '../assets/img/Dashboard.svg';
import { ReactComponent as NewsSvg } from '../assets/img/News.svg';

import {
  Button,
  Card,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  AddCircle,
  ArrowForwardIos,
  Description,
  HomeWork,
  People,
  PeopleAlt,
  Warehouse,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawerSupporto } from '../redux/app/slice';

export default function SideMenuContent({ onNavigate, onItemClick }) {
  const { user } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  // const theme = useTheme();
  // var menuColor = theme.palette.primary.main;
  var menuColor = '#274BAA';
  const { drawerSupportoOpen } = useSelector(state => state.app);

  const styleCardNoActive = {
    p: 0,
    pt: 1,
    border: 'none',
    backgroundColor: 'transparent',
  };
  const styleCardActive = {
    pl: 0,
    pr: 1,
    py: 1,
    border: 'none',
    backgroundColor: 'background.primaryLight',
  };

  const itemStyle = {
    color: '#274BAA !important',
  };

  const getSideMenuAuth = () => {
    switch (user.tipologia) {
      case 'F':
      case 'M':
        return (
          <>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/clienti'}
                onClick={() => onNavigate('/clienti')}
              >
                <ListItemIcon>
                  <PeopleAlt sx={itemStyle} />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="Clienti" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/produttori'}
                onClick={() => onNavigate('/produttori')}
              >
                <ListItemIcon>
                  <Warehouse sx={itemStyle} />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="Produttori" />
              </ListItemButton>
            </ListItem>
            {user.tipologia === 'M' && (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  selected={location.pathname === '/fornitori'}
                  onClick={() => onNavigate('/fornitori')}
                >
                  <ListItemIcon>
                    <HomeWork sx={itemStyle} />
                  </ListItemIcon>
                  <ListItemText sx={itemStyle} primary="Fornitori" />
                </ListItemButton>
              </ListItem>
            )}

            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/documenti-fatture'}
                onClick={() => onNavigate('/documenti-fatture')}
              >
                <ListItemIcon>
                  <Description sx={itemStyle} />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="Documenti e Fatture" />
              </ListItemButton>
            </ListItem>

            <Divider sx={{ my: 2 }} />
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/report'}
                onClick={() => onNavigate('/report')}
              >
                <ListItemIcon>
                  <ReportSvg
                    width={14}
                    height={14}
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="Report" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/comunicazioni'}
                onClick={() => onNavigate('/comunicazioni')}
              >
                <ListItemIcon>
                  <NewsSvg
                    width={14}
                    height={14}
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="News e Comunicazioni" />
              </ListItemButton>
            </ListItem>
          </>
        );
      case 'A':
        return (
          <>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/documenti-fatture'}
                onClick={() => onNavigate('/documenti-fatture')}
              >
                <ListItemIcon>
                  <Description sx={itemStyle} />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="Documenti e Fatture" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/comunicazioni'}
                onClick={() => onNavigate('/comunicazioni')}
              >
                <ListItemIcon>
                  <NewsSvg
                    width={14}
                    height={14}
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="News e Comunicazioni" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/report-pagamenti'}
                onClick={() => onNavigate('/report-pagamenti')}
              >
                <ListItemIcon>
                  <ReportSvg
                    width={14}
                    height={14}
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="Report Pagamenti" />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ my: 2 }} />
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={drawerSupportoOpen}
                onClick={() => {
                  onItemClick(() => {
                    dispatch(toggleDrawerSupporto());
                  });
                }}
              >
                <ListItemIcon>
                  <SupportoSvg
                    width={14}
                    height={14}
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="Supporto" />
              </ListItemButton>
            </ListItem>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Button
        startIcon={<AddCircle />}
        variant="contained"
        onClick={() => onNavigate('/noleggi/noleggio/new', { replace: true })}
        sx={{ pt: 3, pb: 3 }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, lineHeight: '16px', textAlign: 'left' }}
        >
          <span>
            Nuova
            <br />
            Proposta Noleggio
          </span>
        </Typography>
      </Button>

      <ListItem disablePadding sx={{ display: 'block', mt: 2 }}>
        <ListItemButton
          selected={location.pathname === '/dashboard'}
          onClick={() => onNavigate('/dashboard')}
        >
          <ListItemIcon>
            <DashboardSvg width={14} height={14} style={{ color: menuColor }} />
          </ListItemIcon>
          <ListItemText sx={itemStyle} primary="Dashboard" />
        </ListItemButton>
      </ListItem>

      <Divider sx={{ my: 2 }} />

      <Card
        sx={
          location.pathname.includes('/noleggi/attivi')
            ? styleCardActive
            : styleCardNoActive
        }
      >
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton onClick={() => onNavigate('/noleggi/attivi')}>
            <ListItemIcon>
              <OpAttiveSvg
                width={14}
                height={14}
                style={{ color: menuColor }}
              />
            </ListItemIcon>
            <ListItemText sx={itemStyle} primary="Operazioni Attive" />
          </ListItemButton>
        </ListItem>

        {location.pathname.includes('/noleggi/attivi') && (
          <List dense sx={{ p: 0, m: 0, ml: 3 }}>
            {[
              { name: 'Proposte In Bozza', path: '/noleggi/attivi/bozza' },
              {
                name: 'Pratiche Inoltrate',
                path: '/noleggi/attivi/inoltrate',
              },
              {
                name: 'Pratiche Confermate',
                path: '/noleggi/attivi/confermate',
              },
              { name: 'Noleggi Attivi', path: '/noleggi/attivi' },
            ].map((link, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  key={index}
                  selected={location.pathname === link.path}
                  onClick={() => onNavigate(link.path)}
                >
                  <ListItemText
                    secondary={link.name}
                    sx={{
                      ...itemStyle,
                      '& p': {
                        fontWeight:
                          location.pathname === link.path
                            ? 'bold !important'
                            : '',
                      },
                    }}
                  />
                  <ListItemIcon>
                    <ArrowForwardIos sx={itemStyle} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <Card
        sx={
          location.pathname.includes('/noleggi/non-attivi')
            ? styleCardActive
            : styleCardNoActive
        }
      >
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton onClick={() => onNavigate('/noleggi/non-attivi')}>
            <ListItemIcon>
              <OpNonAttiveSvg
                width={14}
                height={14}
                style={{ color: menuColor }}
              />
            </ListItemIcon>
            <ListItemText sx={itemStyle} primary="Operazioni Non Attive" />
          </ListItemButton>
        </ListItem>

        {location.pathname.includes('/noleggi/non-attivi') && (
          <List dense sx={{ p: 0, m: 0, ml: 3 }}>
            {[
              {
                name: 'Proposte Scadute',
                path: '/noleggi/non-attivi',
              },
              {
                name: 'Pratiche Declinate',
                path: '/noleggi/non-attivi/declinate',
              },
              {
                name: 'Noleggi Scaduti',
                path: '/noleggi/non-attivi/noleggi-scaduti',
              },
            ].map((link, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  key={index}
                  selected={location.pathname === link.path}
                  onClick={() => onNavigate(link.path)}
                >
                  <ListItemText
                    secondary={link.name}
                    sx={{
                      ...itemStyle,
                      '& p': {
                        fontWeight:
                          location.pathname === link.path
                            ? 'bold !important'
                            : '',
                      },
                    }}
                  />
                  <ListItemIcon>
                    <ArrowForwardIos sx={itemStyle} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <ListItem disablePadding sx={{ display: 'block', mt: 1 }}>
        <ListItemButton
          selected={location.pathname === '/richieste-modifica'}
          onClick={() => onNavigate('/richieste-modifica')}
        >
          <ListItemIcon>
            <RichiestaModificaMenuSvg
              width={14}
              height={14}
              style={{ color: menuColor }}
            />
          </ListItemIcon>
          <ListItemText sx={itemStyle} primary="Richieste di Modifica" />
        </ListItemButton>
      </ListItem>

      <Divider sx={{ my: 2 }} />

      {getSideMenuAuth()}

      <Box sx={{ flexGrow: 1 }} />
      {user?.flg_admin == 1 && (
        <ListItem disablePadding sx={{ display: 'block', mb: 1 }}>
          <ListItemButton
            selected={location.pathname === '/gestione-account'}
            onClick={() => {
              onNavigate('/gestione-account');
            }}
          >
            <ListItemIcon>
              <People sx={itemStyle} />
            </ListItemIcon>
            <ListItemText sx={itemStyle} primary="Gestione Account" />
          </ListItemButton>
        </ListItem>
      )}
    </Stack>
  );
}
