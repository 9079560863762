import { useEffect } from 'react';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getListaCategorieProdotti } from '../../../redux/noleggi/operations';

const SelectCategoriaProdotto = ({
  name,
  placeholder,
  value,
  onChange,
  errors,
}) => {
  const { categorieProdotti } = useSelector(state => state.noleggi);
  const dispatch = useDispatch();

  useEffect(() => {
    if (categorieProdotti.length == 0) {
      dispatch(getListaCategorieProdotti());
    }
  }, []);

  const _onChange = e => {
    onChange({
      target: {
        name: name,
        value: e.target.value,
        descrizione: categorieProdotti.find(
          categoria => categoria.id == e.target.value
        ).descrizione,
      },
    });
  };

  return (
    <>
      <Select
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={_onChange}
      >
        {categorieProdotti?.map(categoria => (
          <MenuItem key={categoria.id} value={categoria.id}>
            {categoria.descrizione}
          </MenuItem>
        ))}
      </Select>
      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </>
  );
};

export default SelectCategoriaProdotto;
