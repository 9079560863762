import { Alert, Box, Card, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { calcolaRata } from '../../../redux/noleggi/api';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/app/slice';
import { formatCurrency } from '../../../utils/formatNumber';

const ImportoCanone = ({
  dettagli = [],
  importo,
  mesi,
  assicurazione,
  backgroundColor = 'white',
  compactMode = false,
}) => {
  const [canone, setCanone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  let _importo = importo;
  if (dettagli.length > 0) {
    _importo = dettagli.reduce((acc, dettaglio) => {
      return acc + dettaglio.importo * dettaglio.quantita;
    }, 0);
  }

  const calcola = async () => {
    if (!_importo || !mesi || _importo == 0 || mesi == 0) return;

    setIsLoading(true);
    try {
      const result = await calcolaRata({
        importo: _importo,
        durata_mesi: mesi,
        flg_assicurazione: assicurazione ? 1 : 0,
      });
      setCanone(result);
    } catch (error) {
      console.error(error);
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    calcola();
  }, [_importo, mesi, assicurazione]);

  const boxStyle =
    backgroundColor == 'transparent'
      ? { backgroundColor: 'transparent', p: 0, border: 'none' }
      : { backgroundColor: 'background.default', p: 4 };

  return (
    <>
      {importo == null || importo == 0 || canone?.rataMensile == null ? (
        <>
          {isLoading ? (
            <CircularProgress size={30} sx={{ position: 'absolute' }} />
          ) : (
            <Alert severity="info" sx={{ my: 4, justifyContent: 'center' }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                Inserire l'importo per calcolare la rata
              </Typography>
            </Alert>
          )}
        </>
      ) : (
        <Card
          sx={{
            ...boxStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isLoading && (
            <CircularProgress size={30} sx={{ position: 'absolute' }} />
          )}
          <Box sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>
            {!compactMode && (
              <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
                Canone Rata (mensile):
              </Typography>
            )}
            <Typography
              variant="h5"
              component="span"
              sx={{ color: 'primary.main', fontWeight: 'bold' }}
            >
              {formatCurrency(canone?.rataMensile)}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              sx={{ color: 'primary.main' }}
            >
              /mese per {canone?.n_mesi} Mesi (senza IVA)
            </Typography>
            <br />
            <Typography variant="caption">
              {formatCurrency(canone?.rataMensileIva)}/mese per {canone?.n_mesi}{' '}
              Mesi (IVA inclusa)
            </Typography>
          </Box>
        </Card>
      )}
    </>
  );
};

export default ImportoCanone;
