const downloadBlob = (blobData, fileType, filename) => {
  const url = window.URL.createObjectURL(
    new Blob([blobData], { type: fileType })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export { downloadBlob };
