import {
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingMask from '../../../components/LoadingMask';
import { ArrowBackIos, Save } from '@mui/icons-material';
import { modificaUtente, nuovoUtente } from '../../../redux/account/api';
import useAccountCard from '../hook/useAccountCard';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/app/slice';

export default function AccountCard({ isNewAccount, accountData, onSaved }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { formData, setFormData, handleInputChange, errors, handleSubmit } =
    useAccountCard(accountData);

  const onAccountLoaded = account => {
    setFormData(account);
  };

  const onNuovoAccount = async () => {
    onSalva(async () => {
      await nuovoUtente(formData);
    });
  };

  const onModificaAccount = async () => {
    onSalva(async () => {
      await modificaUtente(formData.id, formData);
    });
  };

  const onSalva = async fn => {
    const validation = handleSubmit();
    if (validation === -1) {
      return;
    }

    setIsLoading(true);
    try {
      const result = await fn();
      onAccountLoaded(result);
      onSaved();
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {isLoading && <LoadingMask />}
      <Stack
        justifyContent="space-between"
        flexDirection="row"
        sx={{
          backgroundColor: 'background.primaryLight',
          p: 2,
          borderRadius: '4px',
        }}
      >
        <Stack flexDirection="row" alignItems="center">
          <IconButton
            sx={{ border: 'none' }}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIos />
          </IconButton>
          <Box sx={{ ml: 1 }}>
            <Typography component="h4" variant="body">
              Gestione Account
            </Typography>
            <Stack flexDirection="row">
              <Typography component="h3" variant="body">
                {isNewAccount ? 'Crea Nuovo Account' : 'Modifica Account'}
              </Typography>
            </Stack>
          </Box>
        </Stack>

        <Button
          size="small"
          variant="contained"
          startIcon={<Save />}
          onClick={isNewAccount ? onNuovoAccount : onModificaAccount}
        >
          {isNewAccount ? 'CREA ACCOUNT' : 'MODIFICA ACCOUNT'}
        </Button>
      </Stack>

      <Card sx={{ backgroundColor: 'background.default' }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle2">Inserisci Dati Account</Typography>
          <Typography variant="body2">
            Puoi salvare l'operazione anche senza inserire i dettagli del
            cliente
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="cognome_nome">Cognome e Nome</FormLabel>
              <TextField
                placeholder="Cognome e Nome"
                name="cognome_nome"
                variant="outlined"
                value={formData?.cognome_nome}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.cognome_nome}
                helperText={errors.cognome_nome}
              />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="data_nascita">Data Nascita</FormLabel>
              <DateField
                placeholder="Data Nascita"
                name="data_nascita"
                value={
                  formData?.data_nascita ? dayjs(formData?.data_nascita) : null
                }
                onChange={value => {
                  let _date = '';
                  if (value && value != '') {
                    _date = value.format('YYYY-MM-DD');
                    if (_date == 'Invalid Date') {
                      _date = '';
                    }
                  }
                  handleInputChange({
                    target: {
                      name: 'data_nascita',
                      value: _date,
                      type: 'date',
                    },
                  });
                }}
                format="DD/MM/YYYY"
                fullWidth
                error={!!errors.data_nascita}
                helperText={errors.data_nascita}
              />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="cf">Codice Fiscale</FormLabel>
              <TextField
                placeholder="Codice Fiscale"
                name="cf"
                value={formData?.cf}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.cf}
                helperText={errors.cf}
              />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="nazionalita">Nazionalità</FormLabel>
              <TextField
                placeholder="Nazionalità"
                name="nazionalita"
                value={formData?.nazionalita}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.nazionalita}
                helperText={errors.nazionalita}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="telefono">Telefono</FormLabel>
              <TextField
                placeholder="Telefono"
                name="telefono"
                value={formData?.telefono}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.telefono}
                helperText={errors.telefono}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, mb: 2 }}>
          <Typography variant="subtitle2">Informazione Accesso:</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                placeholder="Email"
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 6, sm: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="qualifica">Qualifica</FormLabel>
              <TextField
                placeholder="Qualifica"
                name="qualifica"
                value={formData?.qualifica}
                onChange={handleInputChange}
                fullWidth
                error={!!errors.qualifica}
                helperText={errors.qualifica}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
