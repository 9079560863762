import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DocumentiTable from './components/DocumentiTable';
import { useState } from 'react';
import { downloadDocumento } from '../../redux/noleggi/api';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../redux/app/slice';
import LoadingMask from '../../components/LoadingMask';
import { downloadBlob } from '../../utils/download';

export default function DocumentiFatture() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onDownload = async documento => {
    setIsLoading(true);
    try {
      const pdfData = await downloadDocumento(documento.id);
      downloadBlob(pdfData, 'application/pdf', 'documento.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenRichiesta = documento => {
    navigate('/noleggi/noleggio/edit', {
      state: { id: documento.id_richiesta },
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      {isLoading && <LoadingMask />}
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, justifyContent: 'space-between' }}
      >
        <Box>
          <Typography component="h2" variant="h4">
            Documenti
          </Typography>
          <Typography variant="body1">
            Visualizza tutti i documenti relativi alle pratiche di noleggio.
          </Typography>
        </Box>
      </Stack>

      <DocumentiTable
        onOpenRichiesta={onOpenRichiesta}
        onDownload={onDownload}
      />
    </Box>
  );
}
