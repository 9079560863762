import api from '../../config/axiosConfig';
import { handleAsyncApiRequest } from '../asyncWrapper';

/*
 * GET @ /preventivatore/comunicazioni/lista
 * response: { comunicazioni }
 */
export const getListaComunicazioni = handleAsyncApiRequest(async () => {
  const res = await api.get(`/preventivatore/comunicazioni/lista`);
  return res.data.data;
});

/*
 * POST @ preventivatore/comunicazione/{id_comunicazione}
 * data: { id_comunicazione }
 */
export const leggiComunicazione = handleAsyncApiRequest(
  async id_comunicazione => {
    await api.post('/preventivatore/comunicazione/' + id_comunicazione);
  }
);
