import { useEffect, useState } from 'react';
import { getOperazioniReport } from '../../../redux/report/api';

const useOperazioniData = () => {
  const [isLoadingOperazioniData, setIsLoadingOperazioniData] = useState(false);
  const [operazioniData, setOperazioniData] = useState(null);
  const [loadingErrorOperazioniData, setLoadingErrorOperazioniData] =
    useState(null);

  const dataLoad = async () => {
    setIsLoadingOperazioniData(true);
    try {
      const response = await getOperazioniReport();
      setOperazioniData(response);
    } catch (error) {
      setLoadingErrorOperazioniData(error);
    } finally {
      setIsLoadingOperazioniData(false);
    }
  };

  useEffect(() => {
    dataLoad();
  }, []);

  return {
    isLoadingOperazioniData,
    operazioniData,
    loadingErrorOperazioniData,
  };
};

export default useOperazioniData;
