import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  eliminaRichiestaModifica,
  rescindiContratto,
  salvaRichiestaModifica,
} from '../../../redux/noleggi/api';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import RichiestaModificaStep2 from './RichiestaModificaStep2';
import RichiestaModificaStep1 from './RichiestaModificaStep1';
import { useDispatch } from 'react-redux';
import { showAlertDialog } from '../../../redux/app/slice';
import LoadingMask from '../../../components/LoadingMask';

const DialogRichiestaModificaNoleggio = ({
  noleggioData,
  setNoleggioData,
  open,
  onClose,
  onSaved,
}) => {
  const [isConfermaLoading, setIsConfermaLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRescindiLoading, setIsRescindiLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dettagli, setDettagli] = useState();
  const [richiestaModifica, setRichiestaModifica] = useState({
    id: '9622a956e02a0c8a5e4f4d594dd94804',
    id_stato: 'DC',
    nome_stato: 'Da completare',
    flg_firma_digitale: null,
    motivo_rifiuto: null,
    corpo: [
      {
        id: '874e9a69fa61877c7581c39e50adac95',
        id_richiesta_modifica: '9622a956e02a0c8a5e4f4d594dd94804',
        id_richiesta_corpo: '02807e92dd2a0a6ad5622071545b47f0',
        quantita: 1,
        created_at: '2024-10-01T08:41:47.000000Z',
        updated_at: '2024-10-01T08:41:47.000000Z',
        deleted_at: null,
      },
    ],
    penale: 13.92,
    tipo_penale:
      "Calcolo di un terzo dell'importo complessivo dei canoni periodici ancora dovuti",
    rata_attuale: 1.74,
    rata_nuova: 0,
  });
  const dispatch = useDispatch();

  const onPreClose = () => {
    if (noleggioData.richiesta_modifica) {
      dispatch(
        showAlertDialog({
          btnConfermaText: 'SALVA RICHIESTA',
          btnAnnullaText: 'ELIMINA',
          title: 'Salva Richiesta di Modifica',
          message:
            'Vuoi salvare la Richiesta di Modifica? In caso contrario non potrai recuperarla.',
          onConfirm: () => {
            onClose();
          },
          onCancel: async () => {
            setIsLoading(true);
            setError(null);
            try {
              const result = await eliminaRichiestaModifica(
                noleggioData.richiesta_modifica.id
              );
              setRichiestaModifica(result);
            } catch (error) {
              setError(error);
            } finally {
              setIsLoading(false);
              setNoleggioData(prev => {
                return { ...prev, richiesta_modifica: null };
              });
              onClose();
            }
          },
        })
      );
    } else {
      onClose();
    }
  };

  useEffect(() => {
    setIsConfermaLoading(false);
    setIsRescindiLoading(false);
    setError(null);
    if (noleggioData?.richiesta_modifica) {
      setRichiestaModifica(noleggioData?.richiesta_modifica);
    } else {
      setRichiestaModifica(null);
      if (noleggioData?.dettagli != null) {
        setDettagli(
          noleggioData.dettagli.map(dettaglio => {
            return {
              ...dettaglio,
              max: dettaglio.quantita,
              min: 0,
              quantita_attuale: dettaglio.quantita,
            };
          })
        );
      } else {
        setDettagli([]);
      }
    }
  }, [open]);

  const onRescindiContratto = async () => {
    setIsRescindiLoading(true);
    setError(null);
    try {
      const result = await rescindiContratto({ id_richiesta: noleggioData.id });
      setRichiestaModifica(result);
      setNoleggioData(prev => {
        return { ...prev, richiesta_modifica: result };
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsRescindiLoading(false);
    }
  };

  const onConfermaModifiche = async () => {
    setIsConfermaLoading(true);
    setError(null);
    try {
      let corpoData = [];
      dettagli.forEach(dettaglio => {
        if (dettaglio.quantita_attuale - dettaglio.quantita) {
          corpoData.push({
            id_richiesta_corpo: dettaglio.id,
            quantita: dettaglio.quantita_attuale - dettaglio.quantita,
          });
        }
      });
      if (corpoData.length > 0) {
        let data = {
          id_richiesta: noleggioData.id,
          corpo: corpoData,
        };
        const result = await salvaRichiestaModifica(data);
        setNoleggioData(prev => {
          return { ...prev, richiesta_modifica: result };
        });
        setRichiestaModifica(result);
      } else {
        setError('Inserire almeno un bene da rescindere.');
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsConfermaLoading(false);
    }
  };
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Dialog open={open} onClose={onPreClose} fullWidth maxWidth={'lg'}>
      <Box sx={{ backgroundColor: 'background.default' }}>
        <DialogTitle sx={{ p: matchMd ? 3 : matchSm ? 2 : 1 }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={'space-between'}
            alignItems="center"
          >
            <Typography variant="h6" color="primary">
              Richiesta di Modifica Noleggio
            </Typography>
            <Stack direction="row" spacing={2}>
              <IconButton size="small" onClick={onPreClose}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ p: matchMd ? 3 : matchSm ? 2 : 1 }}>
          {richiestaModifica == null && (
            <RichiestaModificaStep1
              onRescindiContratto={onRescindiContratto}
              isRescindiLoading={isRescindiLoading}
              onConfermaModifiche={onConfermaModifiche}
              isConfermaLoading={isConfermaLoading}
              dettagli={dettagli}
              setDettagli={setDettagli}
            />
          )}
          {richiestaModifica != null && (
            <RichiestaModificaStep2
              noleggioData={noleggioData}
              richiestaModifica={richiestaModifica}
              onInoltraRichiesta={onSaved}
              isInoltraLoading={isConfermaLoading}
            />
          )}
          <Box sx={{ mt: 2 }}>
            <ActionErrorMessage error={error} />
          </Box>
          {isLoading ? <LoadingMask /> : <></>}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DialogRichiestaModificaNoleggio;
