import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import OTPInput from '../../../components/OTP';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import { LoadingButton } from '@mui/lab';
import { twoFaSignIn } from '../../../redux/auth/api';

export default function TwoFASignIn({ open, onComplete, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [otp, setOtp] = useState('');

  const handleSubmit = async () => {
    if (otp.length !== 6) {
      setError('Il codice deve essere di 6 cifre');
      return;
    }
    setIsLoading(true);
    try {
      const result = await twoFaSignIn({ codice: otp });
      onComplete(result);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setOtp('');
      setError(null);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ textAlign: 'center', mb: 2 }}>
        Connettiti a Google Authenticator{' '}
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <Typography variant="body1" sx={{ mb: 2 }}>
          Inserisci il codice generato da Google Authenticator
        </Typography>

        <Stack
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{ mb: 2 }}
        >
          <OTPInput
            separator={<span>-</span>}
            value={otp}
            onChange={setOtp}
            onSubmit={handleSubmit}
            length={6}
          />
        </Stack>

        <ActionErrorMessage error={error} />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3, justifyContent: 'center' }}>
        <LoadingButton
          sx={{ minWidth: '130px' }}
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
        >
          CONFERMA
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
