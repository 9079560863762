import api from '../../config/axiosConfig';
import { handleAsyncApiRequest } from '../asyncWrapper';

/*
 * POST @ preventivatore/admin/login
 * body: { email, password }
 * response: {...user, access_token, qrCode, secret}
 */
export const login = handleAsyncApiRequest(async credentials => {
  const res = await api.post('/preventivatore/admin/login', credentials);
  return res.data.data;
});

/*
 * POST @ preventivatore/admin/registrazione
 * body: {  }
 * response: {}
 */
export const registrazione = handleAsyncApiRequest(async data => {
  const res = await api.post('/preventivatore/registrazione', data);
  return res.data.data;
});

/*
 * POST @ preventivatore/admin/newPassword
 * body: { password, password_confirm }
 * response: { user }
 */
export const setNewPassword = handleAsyncApiRequest(async passwords => {
  const res = await api.post('/preventivatore/admin/newPassword', passwords);
  return res.data.data;
});

/*
 * POST @ preventivatore/admin/2fa
 * body: { codice }
 * response: {...user, access_token }
 */
export const twoFaSignIn = handleAsyncApiRequest(async codice => {
  const res = await api.post('/preventivatore/admin/2fa', codice);
  return res.data.data;
});

/*
 * POST @ preventivatore/admin/resetPassword
 * body: { email }
 */
export const resetPassword = handleAsyncApiRequest(async email => {
  const res = await api.post('/preventivatore/admin/resetPassword', email);
  return res.data.data;
});
