import api from '../../config/axiosConfig';
import { handleAsyncApiRequest } from '../asyncWrapper';

/*
 * POST @ preventivatore/admin/utente
 * body: { utente }
 * response: { utente }
 */
export const nuovoUtente = handleAsyncApiRequest(async data => {
  const res = await api.post('/preventivatore/admin/utente', data);
  return res.data.data;
});

/*
 * PUT @ preventivatore/admin/utente/{id}
 * body: { utente }
 * response: { utente }
 */
export const modificaUtente = handleAsyncApiRequest(async (id, data) => {
  const res = await api.put(`/preventivatore/admin/utente/${id}`, data);
  return res.data.data;
});
