import { Box, CircularProgress } from '@mui/material';
const LoadingMask = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 10000,
        backgroundColor: 'rgba(255,255,255, 0.6)',
      }}
    >
      {' '}
      <CircularProgress />{' '}
    </Box>
  );
};

export default LoadingMask;
