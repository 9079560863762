import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
export default function SetTwoFA({ open, handleSubmit, twoFaData }) {
  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle sx={{ textAlign: 'center', mb: 2 }}>
        Connetti a Google Authenticator
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Abbiamo bisogno di autenticare la tua identità tramite Google
            Autheticator. Se è la prima volta che accedi:
          </Typography>
          <ol>
            <li>
              Scarica ed Installa Google Authenticator sul tuo dispositivo
            </li>
            <li>Utilizza l’applicazione per scansione il QR Code</li>
            <li>Inserisci il codice generato da Google Authenticator </li>
          </ol>
        </Box>

        <Stack sx={{ mt: 2, alignItems: 'center' }}>
          <img src={twoFaData?.qrCode} alt="QR Code" />

          <Stack sx={{ mt: 4, width: '100%' }}>
            <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
              Inserisci Codice Autenticazione
            </Typography>
            <Stack
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
            >
              <Alert severity="info" sx={{ width: '100%' }}>
                <Typography variant="body1">{twoFaData?.secret}</Typography>
              </Alert>
            </Stack>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Non riesci a scansionare il codice?
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          size="small"
          sx={{ minWidth: '130px' }}
        >
          CONTINUA
        </Button>
      </DialogActions>
    </Dialog>
  );
}
