import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { ArrowForwardIos, Close } from '@mui/icons-material';
import { leggiNotifica } from '../redux/notifiche/api';
import { clearNotifica } from '../redux/notifiche/slice';
import { useNavigate } from 'react-router-dom';
import { toggleDrawerNotifiche } from '../redux/app/slice';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as NotificaSvg } from '../assets/img/notifiche.svg';
import { ReactComponent as RichiestaModificaSvg } from '../assets/img/modifica.svg';

const giorniSettimana = [
  'Domenica',
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
];
const mesi = [
  'gennaio',
  'febbraio',
  'marzo',
  'aprile',
  'maggio',
  'giugno',
  'luglio',
  'agosto',
  'settembre',
  'ottobre',
  'novembre',
  'dicembre',
];

function NotificationDrawer({ headerRef }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));

  const drawerRef = useRef(null);
  const { drawerNotificheOpen } = useSelector(state => state.app);
  const { notifiche } = useSelector(state => state.notifiche);
  const [headerHeight, setHeaderHeight] = useState(0);

  const formatDate = date => {
    const today = new Date();
    const d = new Date(date);
    let f = `${giorniSettimana[d.getDay()]} ${d.getDate()} ${mesi[d.getMonth()]} ${d.getFullYear()}`;
    if (
      d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth() &&
      d.getFullYear() === today.getFullYear()
    ) {
      f = `Oggi - ${f}`;
    }

    return f;
  };

  const onNotificaClick = notifica => {
    onLeggiNotifica(notifica.id);
    onToggleDrawer();
    switch (notifica.tipologia) {
      case 'MA':
      case 'MR':
        navigate('/richieste-modifica/edit', {
          state: { id: notifica.extra_dati.id },
        });
        break;
      case 'RQ':
      case 'RR':
        navigate('/noleggi/noleggio/edit', {
          state: { id: notifica.extra_dati.id },
        });
        break;
    }
  };

  //group by created_at day
  const groupedNotifiche = notifiche.reduce((acc, notifica) => {
    const date = formatDate(notifica.created_at);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(notifica);
    return acc;
  }, {});

  const onLeggiNotifica = async id => {
    try {
      dispatch(clearNotifica(id));
      await leggiNotifica({ id_notifica: id });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const _headerHeight = headerRef?.current?.clientHeight ?? 0;
    setHeaderHeight(_headerHeight);
  }, [headerRef]);

  useEffect(() => {
    document.addEventListener('mousedown', e => {
      if (drawerRef) {
        //se clicco outside drawer lo chiudo
        const drawerPos = drawerRef?.current?.getBoundingClientRect();
        if (!drawerPos) return;
        if (e.pageX < drawerPos.x) {
          onToggleDrawer();
        }
      }
    });

    return () => {
      document.removeEventListener('mousedown', () => {});
    };
  }, []);

  const onToggleDrawer = () => {
    dispatch(toggleDrawerNotifiche());
  };

  return (
    <Drawer
      variant={drawerNotificheOpen && matchMd ? 'permanent' : 'temporary'}
      anchor="right"
      open={drawerNotificheOpen}
      onClose={onToggleDrawer}
      sx={{
        flexShrink: 0,
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'secondary.main',
          minWidth: '200px',
          height: matchMd ? 'calc(100% - ' + headerHeight + 'px)' : '100%',
          position: 'fixed',
          top: matchMd ? headerHeight : 0,
        },
      }}
    >
      <Stack
        ref={drawerRef}
        sx={{
          width: matchLg ? '40vw' : matchMd ? '60dvw' : '100vw',
          height: '100%',
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent={'space-between'}
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography variant="h6" color="white">
            Notifiche
          </Typography>
          {matchMd ? (
            <></>
          ) : (
            <IconButton size="small" onClick={onToggleDrawer}>
              <Close />
            </IconButton>
          )}
        </Stack>

        {notifiche.length == 0 ? (
          <Stack sx={{ p: 2 }}>
            <Typography variant="body2" color="white">
              Nessuna notifica presente
            </Typography>
          </Stack>
        ) : (
          <></>
        )}

        {Object.keys(groupedNotifiche).map(date => (
          <Stack key={date} sx={{ p: 2, pt: 0 }}>
            <Typography variant="subtitle1" color="white">
              {date}
            </Typography>
            <Stack>
              {groupedNotifiche[date].map(notifica => (
                <Card key={notifica.id} sx={{ mb: 1 }}>
                  <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
                    <Stack
                      justifyContent={'center'}
                      alignItems={'center'}
                      sx={{ minWidth: 28, width: 28 }}
                    >
                      {notifica.tipologia == 'MA' ||
                      notifica.tipologia == 'MR' ? (
                        <RichiestaModificaSvg
                          style={{ width: 28, height: 28 }}
                        />
                      ) : (
                        <NotificaSvg sx={{ width: 28, height: 28 }} />
                      )}
                    </Stack>

                    <Box sx={{ flexGrow: 1, ml: 2 }}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: 'Poppins-SemiBold' }}
                      >
                        {notifica.titolo.toUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {notifica.descrizione}
                      </Typography>
                    </Box>
                    {(notifica.tipologia == 'RQ' ||
                      notifica.tipologia == 'RR' ||
                      notifica.tipologia == 'MA' ||
                      notifica.tipologia == 'MR') &&
                    notifica.extra_dati?.id ? (
                      <IconButton
                        sx={{ border: 'none' }}
                        size="small"
                        onClick={() => onNotificaClick(notifica)}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Drawer>
  );
}

export default NotificationDrawer;
