import province from '../../../data/province.json';
import { FormHelperText, MenuItem, Select } from '@mui/material';

const SelectProvince = ({
  name,
  placeholder,
  value,
  onChange,
  errors,
  readOnly = false,
}) => {
  return (
    <>
      <Select
        name={name}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={onChange}
        inputProps={{ readOnly: readOnly }}
      >
        {province.map(provincia => (
          <MenuItem key={provincia.sigla} value={provincia.sigla}>
            {provincia.nome}
          </MenuItem>
        ))}
      </Select>
      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </>
  );
};

export default SelectProvince;
