import {
  Box,
  Card,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/app/slice';
import useRegisterForm from '../hook/useRegisterForm';
import SelectProvince from '../../Noleggi/components/SelectProvince';
import { LoadingButton } from '@mui/lab';
import { registrazione } from '../../../redux/auth/api';

export default function RegisterForm({ onAccountRegistered, idRichiesta }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { formData, handleInputChange, errors, handleSubmit } =
    useRegisterForm();

  const onRegistrati = async () => {
    const validation = handleSubmit();
    if (validation === -1) {
      return;
    }

    setIsLoading(true);
    try {
      await registrazione({ ...formData, id: idRichiesta });
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
      return;
    } finally {
      setIsLoading(false);
    }

    onAccountRegistered();
  };

  return (
    <Box sx={{ backgroundColor: 'background.default' }}>
      <Box sx={{ position: 'relative', p: 2 }}>
        <Card
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 10,
            height: 10,
            bgcolor: 'background.darkest',
            color: 'white',
            zIndex: 1,
            top: '22px',
          }}
        >
          <Typography variant="h6" component="h3">
            1
          </Typography>
        </Card>
        <Box sx={{ px: 6 }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6">Inserisci Dati Aziendali</Typography>
            <Typography variant="body2">
              Inserisci i dati della tua azienda
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="ragione_sociale">Ragione Sociale</FormLabel>
                <TextField
                  placeholder="Ragione Sociale"
                  name="ragione_sociale"
                  variant="outlined"
                  value={formData?.ragione_sociale}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.ragione_sociale}
                  helperText={errors.ragione_sociale}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="cf">Codice Fiscale</FormLabel>
                <TextField
                  placeholder="Codice Fiscale"
                  name="cf"
                  variant="outlined"
                  value={formData?.cf}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.cf}
                  helperText={errors.cf}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="piva">Partita Iva</FormLabel>
                <TextField
                  placeholder="Partita Iva"
                  name="piva"
                  value={formData?.piva}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.piva}
                  helperText={errors.piva}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Inserisci Indirizzi:
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 3 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="sede_indirizzo">Indirizzo Sede</FormLabel>
                <TextField
                  placeholder="Indirizzo Sede"
                  name="sede_indirizzo"
                  value={formData?.sede_indirizzo}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_indirizzo}
                  helperText={errors.sede_indirizzo}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 6, sm: 2 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="sede_civico">N. Civico</FormLabel>
                <TextField
                  placeholder="N. Civico"
                  name="sede_civico"
                  value={formData?.sede_civico}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_civico}
                  helperText={errors.sede_civico}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 6, sm: 2 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="sede_cap">CAP</FormLabel>
                <TextField
                  placeholder="CAP"
                  name="sede_cap"
                  value={formData?.sede_cap}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_cap}
                  helperText={errors.sede_cap}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="sede_citta">Città</FormLabel>
                <TextField
                  placeholder="Città"
                  name="sede_citta"
                  value={formData?.sede_citta}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_citta}
                  helperText={errors.sede_citta}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 3 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="sede_provincia">Provincia</FormLabel>
                <SelectProvince
                  name="sede_provincia"
                  placeholder="Provincia"
                  value={formData?.sede_provincia}
                  onChange={handleInputChange}
                  errors={errors.sede_provincia}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={{ position: 'relative', p: 2, mt: 4 }}>
        <Card
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 10,
            height: 10,
            bgcolor: 'background.darkest',
            color: 'white',
            zIndex: 1,
            top: '22px',
          }}
        >
          <Typography variant="h6" component="h3">
            2
          </Typography>
        </Card>
        <Box sx={{ px: 6 }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6">Inserisci Contatti</Typography>
            <Typography variant="body2">
              Inserisci i contatti della tua azienda
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  placeholder="Email"
                  name="email"
                  variant="outlined"
                  value={formData?.email}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="telefono">Telefono</FormLabel>
                <TextField
                  placeholder="Telefono"
                  name="telefono"
                  variant="outlined"
                  value={formData?.telefono}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.telefono}
                  helperText={errors.telefono}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Stack flexDirection={'row'} justifyContent={'center'} sx={{ mt: 20 }}>
        <Stack
          sx={{
            maxWidth: '300px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <LoadingButton
              color="secondary"
              variant="contained"
              sx={{ minWidth: '200px' }}
              loading={isLoading}
              onClick={onRegistrati}
            >
              REGISTRATI
            </LoadingButton>
          </Box>

          <Typography
            variant="caption"
            sx={{ textAlign: 'center', mt: 2, display: 'inline-block' }}
          >
            Cliccando "Registrati" confermi di aver letto e preso visione
            dei&nbsp;
            <Link sx={{ fontWeight: 'bold', cursor: 'pointer' }}>
              Documenti di Privacy
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
