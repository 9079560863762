import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ActionErrorMessage from '../../components/ActionErrorMessage';
import FornitoreCard from './components/FornitoreCard';

export default function Fornitore() {
  const navigate = useNavigate();
  const location = useLocation();
  const [fornitoreData, setFornitoreData] = useState(null);
  const initialState = { id: null };
  const [loadingError, setLoadingError] = useState(null);

  const onPageLoad = async () => {
    if (location.pathname.includes('new')) {
      setFornitoreData(initialState);
    } else if (location.pathname.includes('edit')) {
      try {
        setFornitoreData(location.state.fornitore);
      } catch (error) {
        setLoadingError(error);
      }
    } else {
      navigate(-1);
    }
  };

  const onSaved = () => {
    navigate(-1);
  };

  useEffect(() => {
    onPageLoad();
  }, [location.pathname]);

  return (
    <Stack>
      {loadingError != null && <ActionErrorMessage error={loadingError} />}
      {fornitoreData != null ? (
        <FornitoreCard
          isNewFornitore={fornitoreData.id == null}
          fornitoreData={fornitoreData}
          onSaved={onSaved}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
}
