import api from '../../config/axiosConfig';
import { handleAsyncApiRequest } from '../asyncWrapper';

/*
 * POST @ preventivatore/tempRichiesta
 * body: { importo, durata_mesi, id_utente }
 * response: { id }
 */
export const tempRichiesta = handleAsyncApiRequest(async data => {
  const res = await api.post('/preventivatore/tempRichiesta', data);
  return res.data.data;
});

/*
 * POST @ preventivatore/richiesta
 * body: { importo, durata_mesi, id_utente }
 * response: { noleggio }
 */
export const richiediNoleggio = handleAsyncApiRequest(async data => {
  const res = await api.post('/preventivatore/richiesta', data);
  return res.data.data;
});

/*
 * GET @ /preventivatore/richiesta/{id}
 * response: { noleggio }
 */
export const getNoleggio = handleAsyncApiRequest(async id => {
  const res = await api.get(`/preventivatore/richiesta/${id}`);
  return res.data.data;
});

/*
 * POST @ preventivatore/richiesta/{id}/confermaDati
 * body: {}
 * response: { noleggio }
 */
export const confermaDati = handleAsyncApiRequest(async id => {
  const res = await api.post(`preventivatore/richiesta/${id}/confermaDati`);
  return res.data.data;
});

/*
 * GET @ preventivatore/rata
 * params: [importo, durata_mesi, flg_assicurazione]
 * response: { n_mesi, rataMensile, rataMensileIva }
 */
export const calcolaRata = handleAsyncApiRequest(async params => {
  const res = await api.get(`preventivatore/rata`, { params });
  return res.data.data;
});

/*
 * POST @ preventivatore/salvaRichiesta
 * body: formData
 * response: richiesta
 */
export const salvaRichiesta = handleAsyncApiRequest(async formData => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const res = await api.post(`preventivatore/salvaRichiesta`, formData, config);
  return res.data.data;
});

/*
 * POST @ preventivatore/salvaRichiesta
 * body: richiesta
 * response: richiesta
 */
export const completaRichiesta = handleAsyncApiRequest(async data => {
  if (data.id == null) {
    delete data.id;
  }
  const res = await api.post(`preventivatore/completaRichiesta`, data);
  return res.data.data;
});

/*
 * GET @ preventivatore/proposta/{id}
 * params: id
 * response: binary
 */
export const getPdfProposta = handleAsyncApiRequest(async id => {
  const config = {
    responseType: 'arraybuffer',
  };
  const res = await api.get(`preventivatore/proposta/${id}`, config);
  return res.data;
});

/*
 * GET @ preventivatore/confermaRichiesta
 * body: formData
 * response: richiesta
 */
export const confermaRichiesta = handleAsyncApiRequest(async formData => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const res = await api.post(
    `preventivatore/confermaRichiesta`,
    formData,
    config
  );
  return res.data.data;
});

/*
 * GET @ preventivatore/downloadDocumento/
 * params: id_documento
 * response: binary
 */
export const downloadDocumento = handleAsyncApiRequest(async id => {
  const config = {
    responseType: 'arraybuffer',
  };
  const params = {
    id_documento: id,
  };
  const res = await api.get(`preventivatore/downloadDocumento`, {
    ...config,
    params,
  });
  return res.data;
});

/*
 * DELETE @ preventivatore/intervento/{id}
 * params: id
 */
export const deleteIntervento = handleAsyncApiRequest(async id => {
  const res = await api.delete(`preventivatore/intervento/${id}`);
  return res.data;
});

/*
 * GET @ preventivatore/intervento/{id}
 * params: id
 * response: binary
 */
export const downloadCertificatoIntervento = handleAsyncApiRequest(async id => {
  const config = {
    responseType: 'arraybuffer',
  };
  const res = await api.get(`preventivatore/intervento/${id}`, config);
  return res.data;
});

/*
 * POST @ preventivatore/intervento/
 * body: {intervento}
 * response: intervento
 */
export const creaIntervento = handleAsyncApiRequest(async formData => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const res = await api.post(`preventivatore/intervento`, formData, config);
  return res.data;
});

/*
 * GET @ preventivatore/intervento/
 * params: id_richiesta
 * response: [interventi]
 */
export const getInterventi = handleAsyncApiRequest(async id_richiesta => {
  const res = await api.get(`preventivatore/interventi`, {
    params: { id_richiesta },
  });
  return res.data.data;
});

/*
 * POST @ preventivatore/modifica/salva
 * body: data
 * response: richiestaModifica
 */
export const salvaRichiestaModifica = handleAsyncApiRequest(async data => {
  const res = await api.post(`preventivatore/modifica/salva`, data);
  return res.data.data;
});

/*
 * POST @ preventivatore/rescindiContratto/
 * body: {id_richiesta}
 * response: richiestaModifica
 */
export const rescindiContratto = handleAsyncApiRequest(async data => {
  const res = await api.post(`preventivatore/rescindiContratto`, data);
  return res.data.data;
});

/*
 * GET @ preventivatore/modifica/show/{id}
 * params: id
 * response: richiestaModifica
 */
export const getRichiestaModifica = handleAsyncApiRequest(async id => {
  const res = await api.get(`preventivatore/modifica/show/${id}`);
  return res.data.data;
});

/*
 * GET @ preventivatore/modifica/download/:id_documento
 * params: id_documento
 * response: binary
 */
export const downloadDocumentoModifica = handleAsyncApiRequest(
  async id_documento => {
    const config = {
      responseType: 'arraybuffer',
    };
    const res = await api.get(
      `preventivatore/modifica/download/${id_documento}`,
      config
    );
    return res.data;
  }
);

/*
 * POST @ preventivatore/modifica/{id}/rifiuta
 * params: id, {flg_firma_digitale}
 * response: richiestaModifica
 */
export const inoltraRichiestaModifica = handleAsyncApiRequest(
  async (id, data) => {
    const res = await api.post(`preventivatore/modifica/${id}/inoltra`, data);
    return res.data.data;
  }
);

/*
 * POST @ preventivatore/modifica/{id}/rifiuta
 * params: id
 * response: richiestaModifica
 */
export const rifiutaRichiestaModifica = handleAsyncApiRequest(async id => {
  const res = await api.post(`preventivatore/modifica/${id}/rifiuta`);
  return res.data.data;
});

/*
 * POST @ preventivatore/modifica/{id}/elimina
 * params: id
 * response: richiestaModifica
 */
export const eliminaRichiestaModifica = handleAsyncApiRequest(async id => {
  const res = await api.post(`preventivatore/modifica/${id}/elimina`);
  return res.data.data;
});

/*
 * POST @ preventivatore/salvaRichiestaAzienda
 * body: formData
 * response: richiesta
 */
export const salvaRichiestaAzienda = handleAsyncApiRequest(async formData => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const res = await api.post(
    `preventivatore/salvaRichiestaAzienda`,
    formData,
    config
  );
  return res.data.data;
});

/*
 * POST @ preventivatore/confermaRichiestaAzienda
 * body: formData
 * response: richiesta
 */
export const confermaRichiestaAzienda = handleAsyncApiRequest(
  async formData => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const res = await api.post(
      `preventivatore/confermaRichiestaAzienda`,
      formData,
      config
    );
    return res.data.data;
  }
);

/*
 * POST @ preventivatore/salvaRichiestaMediatore
 * body: formData
 * response: richiesta
 */
export const salvaRichiestaMediatore = handleAsyncApiRequest(async formData => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const res = await api.post(
    `preventivatore/salvaRichiestaMediatore`,
    formData,
    config
  );
  return res.data.data;
});

/*
 * POST @ preventivatore/confermaRichiestaMediatore
 * body: formData
 * response: richiesta
 */
export const confermaRichiestaMediatore = handleAsyncApiRequest(
  async formData => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const res = await api.post(
      `preventivatore/confermaRichiestaMediatore`,
      formData,
      config
    );
    return res.data.data;
  }
);

/*
 * GET @ preventivatore/fornitori
 * params: {}
 * response: listaFornitori
 */
export const getListaFornitori = handleAsyncApiRequest(async () => {
  const res = await api.get(`preventivatore/fornitori`);
  return res.data.data;
});

/*
 * GET @ preventivatore/fornitore/{id}/utenti
 * params: {}
 * response: listaUtentiFornitori
 */
export const getListaUtentiFornitore = handleAsyncApiRequest(async id => {
  const res = await api.get(`preventivatore/fornitore/${id}/utenti`);
  return res.data.data;
});
