import api from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleAsyncThunk } from '../asyncWrapper';

/*
 * GET @ preventivatore/comunicazioni/new
 * response: [comunicazioni]
 */
export const getComunicazioni = createAsyncThunk(
  'comunicazioni/getComunicazioni',
  handleAsyncThunk(async () => {
    const res = await api.get('/preventivatore/comunicazioni/new');
    return res.data.data;
  })
);
