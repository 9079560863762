import { Error } from '@mui/icons-material';
import {
  Stack,
  Grid2 as Grid,
  Typography,
  Card,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { formatCurrency } from '../../../utils/formatNumber';
import StatoModificaSvg from '../../../assets/img/StatoModifica.svg';
import { formatStringDate } from '../../../utils/formadDate';

const CambiamentiRichiestaModifica = ({ richiestaModifica }) => {
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2, position: 'relative' }}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Card
            sx={{
              backgroundColor: 'background.default',
              position: 'relative',
              overflow: 'unset',
            }}
          >
            <Stack sx={{ pl: 2, pr: 2, pb: 3 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 12 }}>
                  <Typography component="h4" variant="body">
                    Dati Operazione Attuale
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>Importo Totale:</Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    {formatCurrency(
                      richiestaModifica?.dati_precedenti?.importo
                    )}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>Canone Mensile:</Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography
                    sx={{ fontFamily: 'Poppins-SemiBold' }}
                    color="primary"
                  >
                    {formatCurrency(
                      richiestaModifica?.dati_precedenti?.canone_no_iva
                    )}{' '}
                    (senza IVA)
                  </Typography>
                  <Typography>
                    {formatCurrency(
                      richiestaModifica?.dati_precedenti?.canone_iva
                    )}{' '}
                    (IVA inclusa)
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>Durata:</Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography>
                    {richiestaModifica?.dati_precedenti?.durata} mesi
                  </Typography>
                </Grid>

                <Grid size={{ xs: 12, md: 6 }}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography> Data Inizio Noleggio:</Typography>
                    <Typography>
                      {richiestaModifica?.dati_precedenti?.data_inizio != null
                        ? formatStringDate(
                            richiestaModifica?.dati_precedenti?.data_inizio
                          )
                        : ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography> Data Fine Noleggio:</Typography>
                    <Typography>
                      {richiestaModifica?.dati_precedenti?.data_fine != null
                        ? formatStringDate(
                            richiestaModifica?.dati_precedenti?.data_fine
                          )
                        : ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography>Assicurazione:</Typography>
                    <Typography>
                      {richiestaModifica?.dati_precedenti?.flg_assicurazione
                        ? 'ATTIVA'
                        : 'NON ATTIVA'}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            {matchMd ? (
              <Stack
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: -20,
                  zIndex: 100,
                }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <img src={StatoModificaSvg} alt="" />
              </Stack>
            ) : (
              <></>
            )}
          </Card>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <Card sx={{ backgroundColor: '#F0F6FD' }}>
            <Stack sx={{ pl: 2, pr: 2, pb: 3 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 12 }}>
                  <Typography component="h4" variant="body">
                    Dati Operazione Modifica
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>Importo Totale:</Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    {formatCurrency(richiestaModifica?.dati_modifica?.importo)}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>Canone Mensile:</Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography
                    sx={{ fontFamily: 'Poppins-SemiBold' }}
                    color="primary"
                  >
                    {formatCurrency(
                      richiestaModifica?.dati_modifica?.canone_no_iva
                    )}{' '}
                    (senza IVA)
                  </Typography>
                  <Typography>
                    {formatCurrency(
                      richiestaModifica?.dati_modifica?.canone_iva
                    )}{' '}
                    (IVA inclusa)
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>Durata:</Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography>
                    {richiestaModifica?.dati_modifica?.durata} mesi
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography> Data Inizio Noleggio:</Typography>
                    <Typography>
                      {richiestaModifica?.dati_modifica?.data_inizio != null
                        ? formatStringDate(
                            richiestaModifica?.dati_modifica?.data_inizio
                          )
                        : ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography> Data Fine Noleggio:</Typography>
                    <Typography>
                      {richiestaModifica?.dati_modifica?.data_fine != null
                        ? formatStringDate(
                            richiestaModifica?.dati_modifica?.data_fine
                          )
                        : ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography>Assicurazione:</Typography>
                    <Typography>
                      {richiestaModifica?.dati_modifica?.flg_assicurazione
                        ? 'ATTIVA'
                        : 'NON ATTIVA'}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid size={{ xs: 12, md: 6 }}></Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Card sx={{ backgroundColor: '#F0F6FD' }}>
            <Stack flexDirection={'row'} sx={{ pl: 2, pr: 2, pb: 3 }}>
              <Error sx={{ color: '#446BD5', fontSize: 28 }} />
              <Stack sx={{ ml: 2 }}>
                <Typography
                  component="h2"
                  sx={{
                    fontFamily: 'Poppins-SemiBold',
                    color: '#446BD5',
                    fontSize: 18,
                  }}
                >
                  Costo Penale
                </Typography>
                <Typography>
                  La modifica del contratto di Noleggio comporta un pagamento di
                  una penale del valore di{' '}
                  <Typography
                    component={'span'}
                    sx={{ fontFamily: 'Poppins-SemiBold', fontSize: 18 }}
                  >
                    {formatCurrency(richiestaModifica?.penale)}
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CambiamentiRichiestaModifica;
