import api from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleAsyncThunk } from '../asyncWrapper';

/*
 * GET @ preventivatore/admin/richieste
 * response: [noleggi]
 */
export const getNoleggi = createAsyncThunk(
  'noleggi/getNoleggi',
  handleAsyncThunk(async () => {
    const res = await api.get('/preventivatore/admin/richieste');
    return res.data.data;
  })
);

/*
 * GET @ preventivatore/listaCategorie
 * response: [categorie]
 */
export const getListaCategorieProdotti = createAsyncThunk(
  'noleggi/getListaCategorieProdotti',
  handleAsyncThunk(async () => {
    const res = await api.get('/preventivatore/listaCategorie');
    return res.data.results.data;
  })
);

/*
 * GET @ preventivatore/documenti
 * response: [categorie]
 */
export const getDocumenti = createAsyncThunk(
  'noleggi/getDocumenti',
  handleAsyncThunk(async () => {
    const res = await api.get('/preventivatore/documenti');
    return res.data.data;
  })
);

/*
 * GET @ preventivatore/modifica/lista
 * response: [richiesteModifica]
 */
export const getListaRichiesteModifica = createAsyncThunk(
  'noleggi/getListaRichiesteModifica',
  handleAsyncThunk(async () => {
    const res = await api.get('/preventivatore/modifica/lista');
    return res.data.data;
  })
);
