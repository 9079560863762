import { Box } from '@mui/material';
const HighlightText = ({ text, ...props }) => {
  return (
    <Box
      sx={{
        p: 1,
        ml: 2,
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...props,
      }}
    >
      {text}
    </Box>
  );
};

export default HighlightText;
