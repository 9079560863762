import {
  Box,
  Button,
  InputAdornment,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import NoleggiTable from './components/NoleggiTable';
import { Add, Search } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useState } from 'react';
import useNoleggiTable from './hook/useNoleggiTable';

export default function Noleggi() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const {
    attivi,
    bozza,
    inoltrati,
    confermati,
    proposteScadute,
    declinati,
    noleggiScaduti,
    filter,
    getNoleggiAction,
  } = useNoleggiTable([
    'attivi',
    'bozza',
    'inoltrati',
    'confermati',
    'proposteScadute',
    'declinati',
    'noleggiScaduti',
  ]);
  const [title, setTitle] = useState('Operazioni Attive');
  const nonAttive = location.pathname.includes('non-attivi');
  const [tabValue, setTabValue] = useState(
    nonAttive ? 'bozze_scadute' : 'attivi'
  );

  useEffect(() => {
    if (location.pathname.includes('non-attivi')) {
      setTitle('Operazioni Non Attive');
      if (params.type == null) {
        setTabValue('bozze_scadute');
      } else if (params.type === 'declinate') {
        setTabValue('declinate');
      } else if (params.type === 'noleggi-scaduti') {
        setTabValue('noleggi_scaduti');
      }
    } else {
      setTitle('Operazioni Attive');
      if (params.type == null) {
        setTabValue('attivi');
      } else if (params.type === 'bozza') {
        setTabValue('bozza');
      } else if (params.type === 'inoltrate') {
        setTabValue('inoltrate');
      } else if (params.type === 'confermate') {
        setTabValue('confermate');
      }
    }
  }, [location.pathname, params.type]);

  const onNuovoNoleggio = () => {
    navigate('/noleggi/noleggio/new');
  };

  const tabs = nonAttive
    ? [
        {
          key: 'bozze_scadute',
          text: 'Proposta in Bozza Scadute',
          badgeValue: proposteScadute.noleggiBozzaScaduti.length,
        },
        {
          key: 'declinate',
          text: 'Pratiche Inoltrate Declinate',
          badgeValue: declinati.noleggiDeclinati.length,
        },
        {
          key: 'noleggi_scaduti',
          text: 'Noleggi Scaduti',
          badgeValue: noleggiScaduti.noleggiScaduti.length,
        },
      ]
    : [
        {
          key: 'attivi',
          text: 'Noleggi Attivi',
          badgeValue: attivi.noleggiAttivi.length,
        },
        {
          key: 'bozza',
          text: 'Proposte In Bozza',
          badgeValue: bozza.noleggiBozza.length,
        },
        {
          key: 'inoltrate',
          text: 'Pratiche Inoltrate',
          badgeValue: inoltrati.noleggiInoltrate.length,
        },
        {
          key: 'confermate',
          text: 'Pratiche Confermate',
          badgeValue: confermati.noleggiConfermate.length,
        },
      ];

  const onTabChange = tabValue => {
    if ('attivi' === tabValue) {
      navigate('/noleggi/attivi');
    } else if ('bozza' === tabValue) {
      navigate('/noleggi/attivi/bozza');
    } else if ('inoltrate' === tabValue) {
      navigate('/noleggi/attivi/inoltrate');
    } else if ('confermate' === tabValue) {
      navigate('/noleggi/attivi/confermate');
    } else if ('bozze_scadute' === tabValue) {
      navigate('/noleggi/non-attivi');
    } else if ('declinate' === tabValue) {
      navigate('/noleggi/non-attivi/declinate');
    } else if ('noleggi_scaduti' === tabValue) {
      navigate('/noleggi/non-attivi/noleggi-scaduti');
    } else {
      navigate('/noleggi/attivi');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, justifyContent: 'space-between' }}
      >
        <Stack sx={{ mb: 2 }}>
          <Typography component="h2" variant="h4">
            {title}
          </Typography>
        </Stack>

        {!nonAttive && (
          <Button
            size="small"
            onClick={onNuovoNoleggio}
            startIcon={<Add />}
            variant="contained"
          >
            Nuovo noleggio
          </Button>
        )}
      </Stack>

      <TabContext value={tabValue}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          onChange={(event, newValue) => onTabChange(newValue)}
          textColor="primary"
          TabIndicatorProps={{ style: { display: 'none' } }}
          indicatorColor="transparent"
          sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              sx={{
                m: 1,
                mb: 0,
              }}
              label={
                <Box display="flex" alignItems="center">
                  {tab.text}
                  <Box
                    sx={{
                      marginLeft: 2,
                      minWidth: '20px',
                      minHeight: '20px',
                      borderRadius: '4px',
                      backgroundColor: 'background.primaryMedium',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      color="primary"
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {tab.badgeValue}
                    </Typography>
                  </Box>
                </Box>
              }
              value={tab.key}
            />
          ))}
        </TabList>

        {/* Tab Panels */}
        <TabPanel value="attivi">
          <Typography variant="h6">Noleggi Attivi</Typography>
          <Typography variant="body1">
            Visualizza in dettaglio i Noleggi attualmente in corso.
          </Typography>

          <TextField
            onChange={e => {
              filter(e.target.value, 'attivi');
            }}
            sx={{ mb: 1, mt: 3 }}
            placeholder="Cerca"
            size="small"
            variant="outlined"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              },
            }}
          />
          <NoleggiTable
            noleggi={attivi.noleggiAttivi}
            isLoading={getNoleggiAction?.loading ?? false}
            showSearch={false}
          />
        </TabPanel>
        <TabPanel value="bozza">
          <Typography variant="h6">Proposte di Noleggio In Bozza</Typography>
          <Typography variant="body1">
            Completa la Proposta di Noleggio per procedere alla sua valutazione.
            Le Proposte in Bozza rimangono salvate per 30 giorni, dopo non sarà
            più possibile completarle.
          </Typography>
          <TextField
            onChange={e => {
              filter(e.target.value, 'bozza');
            }}
            sx={{ mb: 1, mt: 3 }}
            placeholder="Cerca"
            size="small"
            variant="outlined"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              },
            }}
          />
          <NoleggiTable
            noleggi={bozza.noleggiBozza}
            isLoading={getNoleggiAction?.loading ?? false}
            showSearch={false}
          />
        </TabPanel>
        <TabPanel value="inoltrate">
          <Typography variant="h6">Pratiche Inoltrate</Typography>
          <Typography variant="body1">
            La Proposta di Noleggio è fase di valutazioen da parte del
            Noleggiatore. Occorre attendere la conferma per procedere con la
            pratica.
          </Typography>
          <TextField
            onChange={e => {
              filter(e.target.value, 'inoltrati');
            }}
            sx={{ mb: 1, mt: 3 }}
            placeholder="Cerca"
            size="small"
            variant="outlined"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              },
            }}
          />
          <NoleggiTable
            noleggi={inoltrati.noleggiInoltrate}
            isLoading={getNoleggiAction?.loading ?? false}
            showSearch={false}
          />
        </TabPanel>
        <TabPanel value="confermate">
          <Typography variant="h6">Pratiche Confermate</Typography>
          <Typography variant="body1">
            La Proposta di Noleggio è stata confermata e la pratica è stata
            avviata.
          </Typography>
          <TextField
            onChange={e => {
              filter(e.target.value, 'confermati');
            }}
            sx={{ mb: 1, mt: 3 }}
            placeholder="Cerca"
            size="small"
            variant="outlined"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              },
            }}
          />
          <NoleggiTable
            noleggi={confermati.noleggiConfermate}
            isLoading={getNoleggiAction?.loading ?? false}
            showSearch={false}
          />
        </TabPanel>
        <TabPanel value="bozze_scadute">
          <Typography variant="h6">Proposte in Bozza Scadute</Typography>
          <Typography variant="body1">
            Le Proposte di Noleggio scadute non sono state completate entro il
            tempo limite. Clona una proposta per completarla nuovamente.
          </Typography>
          <TextField
            onChange={e => {
              filter(e.target.value, 'bozzeScadute');
            }}
            sx={{ mb: 1, mt: 3 }}
            placeholder="Cerca"
            size="small"
            variant="outlined"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              },
            }}
          />
          <NoleggiTable
            noleggi={proposteScadute.noleggiBozzaScaduti}
            isLoading={getNoleggiAction?.loading ?? false}
            showSearch={false}
          />
        </TabPanel>
        <TabPanel value="declinate">
          <Typography variant="h6">Pratiche Inoltrate Declinate</Typography>
          <Typography variant="body1">
            Le pratiche inoltrate declinate non sono state valutate conformi per
            il noleggio.
          </Typography>
          <TextField
            onChange={e => {
              filter(e.target.value, 'declinati');
            }}
            sx={{ mb: 1, mt: 3 }}
            placeholder="Cerca"
            size="small"
            variant="outlined"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              },
            }}
          />
          <NoleggiTable
            noleggi={declinati.noleggiDeclinati}
            isLoading={getNoleggiAction?.loading ?? false}
            showSearch={false}
          />
        </TabPanel>
        <TabPanel value="noleggi_scaduti">
          <Typography variant="h6">Noleggi Scaduti</Typography>
          <Typography variant="body1">
            Visualizza i noleggi conclusi.
          </Typography>

          <TextField
            onChange={e => {
              filter(e.target.value, 'noleggiScaduti');
            }}
            sx={{ mb: 1, mt: 3 }}
            placeholder="Cerca"
            size="small"
            variant="outlined"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              },
            }}
          />
          <NoleggiTable
            noleggi={noleggiScaduti.noleggiScaduti}
            isLoading={getNoleggiAction?.loading ?? false}
            showSearch={false}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
