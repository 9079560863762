import { Stack, Typography } from '@mui/material';

export default function IntestazioneGrafici({ titolo, sottotitolo }) {
  return (
    <>
      {(titolo || sottotitolo) && (
        <Stack sx={{ flex: 1 }} justifyContent={'center'}>
          <Typography component="h3" variant="h7">
            {titolo}
          </Typography>
          {sottotitolo && (
            <Typography variant="body3">{sottotitolo}</Typography>
          )}
        </Stack>
      )}
    </>
  );
}
