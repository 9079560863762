import { Grid2 as Grid, Typography, Box, Stack, Checkbox } from '@mui/material';

const FirmaDocumento = ({ size = 4, spacing = 8 }) => {
  return (
    <Box>
      <Grid container justifyContent={'start'} spacing={spacing} sx={{ my: 4 }}>
        <Grid size={{ xs: 12, sm: size }}>
          <Stack
            flexDirection="row"
            sx={{ opacity: '0.2', pointerEvents: 'none' }}
          >
            <Checkbox
              sx={{
                color: 'blue',
                // '&.MuiButtonBase-root': { color: 'transparent' },
              }}
            />
            <Box>
              <Typography variant="h6" fontWeight="bold">
                Firma Digitale
              </Typography>
              <Typography variant="body2">
                La firma dei documenti digitalmente prevede l'inserimento di un
                codice per autenticare l'utente
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, sm: size }}>
          <Stack flexDirection="row">
            <Checkbox
              defaultChecked
              disabled
              sx={{
                color: 'blue',
                // '&.MuiButtonBase-root': { color: 'transparent' },
              }}
            />
            <Box>
              <Typography variant="h6" fontWeight="bold">
                Firma Materiale
              </Typography>
              <Typography variant="body2">
                La firma materiale dei documenti prevede l'invio da parte del
                cliente finale dei documenti originali firmati
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirmaDocumento;
