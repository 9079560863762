import Pusher from 'pusher-js/with-encryption';
import { CONFIG } from './envConfig';

const pusher = new Pusher(CONFIG.PUSHER_KEY, {
  cluster: CONFIG.PUSHER_CLUSTER,
  enabledTransports: ['ws', 'wss'],
  wsPort: CONFIG.PUSHER_WS_PORT,
  wssPort: CONFIG.PUSHER_WSS_PORT,
  wsHost: CONFIG.PUSHER_HOST,
  forceTLS: true,
});

export default pusher;
