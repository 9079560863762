import api from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleAsyncThunk } from '../asyncWrapper';

/*
 * GET @ preventivatore/aziende
 * response: [clienti]
 */
export const getClienti = createAsyncThunk(
  'clienti/getClienti',
  handleAsyncThunk(async () => {
    const res = await api.get('/preventivatore/aziende');
    return res.data.data;
  })
);
