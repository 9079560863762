import axios from 'axios';

const parseAxiosMessage = error => {
  //se array di errori ritorno con \n
  if (Array.isArray(error)) {
    return error.join('\n');
  }

  return error;
};

export const handleAsyncThunk = asyncFn => async (args, thunkAPI) => {
  try {
    return await asyncFn(args, thunkAPI);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Se è un AxiosError, ritorna il messaggio dall'oggetto response
      return thunkAPI.rejectWithValue(
        parseAxiosMessage(error.response?.data?.message) || error.message
      );
    }
    // Altrimenti ritorna il messaggio di errore generico
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const handleAsyncApiRequest =
  asyncFn =>
  async (...params) => {
    try {
      return await asyncFn(...params);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Se è un AxiosError, ritorna il messaggio dall'oggetto response
        throw parseAxiosMessage(error.response?.data?.message) || error.message;
      }
      // Altrimenti ritorna il messaggio di errore generico
      throw error.message;
    }
  };
