import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import IntestazioneGrafici from './IntestazioneGrafici';
import { Doughnut } from 'react-chartjs-2';

export default function GraficoTorta({
  titolo,
  sottotitolo,
  value,
  labelPos = 'bottom',
  labelAlign = 'center',
}) {
  const [chartData, setChartData] = useState([]);

  const pieColor = [
    '#3476D5',
    '#493FD7',
    '#31BED5',
    '#25449C',
    /* '#25449C',
    '#25449C',
    '#25449C',
    '#25449C', */
  ];

  useEffect(() => {
    if (value) {
      setChartData(formatData(value));
    } else {
      setChartData([]);
    }
  }, [value]);

  const formatData = data => {
    return Object.values(data).map(item => ({
      value: item.counter,
      label: item.nome,
    }));
  };

  return (
    <Stack sx={{ flex: 1 }} justifyContent={'center'}>
      <IntestazioneGrafici titolo={titolo} sottotitolo={sottotitolo} />
      {chartData && (
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            mt: 2,
            flexGrow: 1,
            maxHeight: '200px',
          }}
        >
          <Doughnut
            options={{
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 2,
              cutout: '70%',
              plugins: {
                legend: {
                  position: labelPos,
                  align: labelAlign,
                  labels: {
                    boxWidth: 10,
                    font: {
                      size: 12,
                    },
                    usePointStyle: true,
                  },
                },
              },
            }}
            data={{
              labels: chartData.map(item => item.label),
              datasets: [
                {
                  data: chartData.map(item => item.value),
                  backgroundColor: pieColor,
                },
              ],
            }}
          />
        </Box>
      )}
    </Stack>
  );
}
