import { Stack, Grid2 as Grid } from '@mui/material';
import CardInserimentoStep from './CardInserimentoStep';
import InserisciDatiCliente from './InserisciDatiCliente';
import CaricaFile from './CaricaFile';
import useInserisciDatiCliente from '../hook/useInserisciDatiCliente';
import InserisciDatiFornitore from './InserisciDatiFornitore';
import useInserisciDatiFornitore from '../hook/useInserisciDatiFornitore';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import ConfiguraBeni from './ConfiguraBeni';
import FirmaDocumento from './FirmaDocumento';

const CompilaDatiRichiestaCliente = forwardRef(
  (
    {
      noleggio,
      setNoleggio,
      filePreventivo,
      setFilePreventivo,
      triggerUpdateDati,
      setTriggerUpdateDati,
    },
    ref
  ) => {
    const styleHiddenStack = { mt: 4, opacity: '0.2', pointerEvents: 'none' };
    const styleVisibleStack = { mt: 4 };
    const styleStack =
      noleggio.id_stato !== 'DC' ? styleHiddenStack : styleVisibleStack;

    const useInserisciDatiFornitoreHook = useInserisciDatiFornitore(
      noleggio.fornitore
    );
    const useInserisciDatiClienteHook = useInserisciDatiCliente(
      noleggio.azienda
    );

    const validationForm = (requiresAll = false) => {
      useInserisciDatiClienteHook.setFormData(noleggio.azienda);
      useInserisciDatiFornitoreHook.setFormData(noleggio.fornitore);

      const datiFornitoreValidationResult =
        useInserisciDatiFornitoreHook.handleSubmit(requiresAll);
      if (datiFornitoreValidationResult === -1) return -2;

      const datiClienteValidationResult =
        useInserisciDatiClienteHook.handleSubmit(requiresAll);
      if (datiClienteValidationResult === -1) return -1;

      const formData = new FormData();
      if (noleggio.id != null) formData.append('id', noleggio.id);
      formData.append('id_stato', noleggio.id_stato);
      formData.append('id_step', noleggio.id_step);
      formData.append('durata_mesi', noleggio.durata_mesi);
      formData.append('importo', noleggio.importo);
      formData.append('flg_assicurazione', noleggio.flg_assicurazione);
      formData.append('flg_sdd', noleggio.flg_sdd);

      if (datiFornitoreValidationResult === 1) {
        const fornitoreData = useInserisciDatiFornitoreHook.formData;
        formData.append('fornitore', JSON.stringify(fornitoreData));
      }

      if (datiClienteValidationResult === 1) {
        const aziendaData = useInserisciDatiClienteHook.formData;
        formData.append('azienda', JSON.stringify(aziendaData));
      }

      if (filePreventivo != null) formData.append('preventivo', filePreventivo);

      if (noleggio.dettagli != null)
        formData.append('dettagli', JSON.stringify(noleggio.dettagli));

      return formData;
    };

    useImperativeHandle(ref, () => ({
      validationForm,
    }));

    useEffect(() => {
      if (triggerUpdateDati) {
        useInserisciDatiClienteHook.setFormData(noleggio.azienda);
        useInserisciDatiFornitoreHook.setFormData(noleggio.fornitore);
        setTriggerUpdateDati(false);
      }
    }, [triggerUpdateDati]);

    return (
      <Stack sx={styleStack} spacing={4}>
        <CardInserimentoStep
          number={1}
          title="Carica Preventivo"
          subtitle="Carica il preventivo effettuato dal fornitore"
          component={
            <Grid container justifyContent={'center'} spacing={2}>
              <Grid size={{ xs: 12, sm: 8 }}>
                <CaricaFile
                  alreadyPresentMessage="Hai già caricato un preventivo. Per sovrascriverlo, carica un nuovo file."
                  isPresent={noleggio.flg_preventivo}
                  onLoad={file => setFilePreventivo(file)}
                  onCancel={() => setFilePreventivo(null)}
                />
              </Grid>
            </Grid>
          }
        />
        <CardInserimentoStep
          number={2}
          title="Inserisci Dati del Fornitore"
          subtitle="Inserisci i dati del fornitore da cui vorresti noleggiare i beni."
          component={
            <InserisciDatiFornitore hook={useInserisciDatiFornitoreHook} />
          }
        />
        <CardInserimentoStep
          number={3}
          title="Inserisci i tuoi Dati"
          subtitle="Inserisci i tuoi dati personali e inserisci le informazioni relative al pagamento."
          component={
            <InserisciDatiCliente
              hook={useInserisciDatiClienteHook}
              noleggio={noleggio}
              setNoleggio={setNoleggio}
              viewMode={'read'}
            />
          }
        />
        <CardInserimentoStep
          number={4}
          title="Configura Beni e Servizi"
          subtitle="Censisci i beni e servizi richiesti al noleggio"
          component={
            <ConfiguraBeni
              noleggio={noleggio}
              beni={noleggio.dettagli ?? []}
              setBeni={beni => {
                setNoleggio({ ...noleggio, dettagli: beni });
              }}
            />
          }
        />
        <CardInserimentoStep
          number={5}
          title="Firma Documento"
          subtitle="Inserisci la modalità di firma"
          component={<FirmaDocumento />}
        />
      </Stack>
    );
  }
);

export default CompilaDatiRichiestaCliente;
