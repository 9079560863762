import { useEffect, useState } from 'react';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { getListaUtentiFornitore } from '../../../redux/noleggi/api';

const SelectFornitoreUtente = ({
  idFornitore,
  name,
  placeholder,
  value,
  onChange,
  errors,
}) => {
  const [listaUtenti, setListaUtenti] = useState([]);

  useEffect(() => {
    if (idFornitore) {
      const loadUtenti = async () => {
        let response = await getListaUtentiFornitore(idFornitore);
        setListaUtenti(response);
      };

      loadUtenti();
    } else {
      setListaUtenti([]);
      onChange(null);
    }
  }, [idFornitore]);

  const _onChange = e => {
    onChange({
      target: {
        name: name,
        value: e.target.value,
        cognome_nome: listaUtenti.find(utente => utente.id == e.target.value)
          .cognome_nome,
      },
    });
  };

  return (
    <>
      <Select
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={_onChange}
        sx={{ flex: 1 }}
      >
        {listaUtenti?.map(utente => (
          <MenuItem key={utente.id} value={utente.id}>
            {utente.cognome_nome}
          </MenuItem>
        ))}
      </Select>
      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </>
  );
};

export default SelectFornitoreUtente;
