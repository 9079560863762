import api from '../../config/axiosConfig';
import { handleAsyncApiRequest } from '../asyncWrapper';

/*
 * GET @ /preventivatore/azienda/{id}
 * response: { cliente }
 */
export const getCliente = handleAsyncApiRequest(async id => {
  const res = await api.get(`/preventivatore/azienda/${id}`);
  return res.data.data;
});
