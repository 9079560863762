import { useEffect, useState } from 'react';
import { Card, Stack, Typography } from '@mui/material';
import LoadingMask from '../../../components/LoadingMask';
import { getAccountReport } from '../../../redux/report/api';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/it';
import GraficoBarreOrizzontale from './GraficoBarreOrizzontale';
import IntestazioneGrafici from './IntestazioneGrafici';
import GraficoBarreFiltrato from './GraficoBarreFiltrato';

dayjs.locale('it');

export default function AccountPanel() {
  const [dataInizio, setDataInizio] = useState(
    dayjs().subtract(1, 'month').startOf('month')
  );
  const [dataFine, setDataFine] = useState(
    dayjs().add(1, 'month').endOf('month')
  );
  const [accountData, setAccountData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);

  const loadData = async (data_inizio, data_fine) => {
    setIsLoading(true);
    try {
      const response = await getAccountReport(data_inizio, data_fine);
      setAccountData(response);
    } catch (error) {
      setLoadingError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataInizio && dataFine) {
      if (dataInizio < dataFine) {
        loadData(
          dataInizio.format('YYYY-MM-DD'),
          dataFine.format('YYYY-MM-DD')
        );
      }
    }
  }, [dataInizio, dataFine]);

  return (
    <Stack sx={{ marginTop: 5, position: 'relative' }}>
      <Card sx={{ backgroundColor: '#FAFAFA' }}>
        <Typography component="h3" variant="h5" sx={{ color: '#284BAA' }}>
          Account di Gestione
        </Typography>
        <Stack sx={{ marginTop: 3, marginBottom: 3 }} flexDirection="row">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <DatePicker
              label="Data Inizio"
              value={dataInizio}
              format="DD/MM/YYYY"
              onChange={val => setDataInizio(val)}
              sx={{ marginRight: 3 }}
            />
            <DatePicker
              label="Data Fine"
              value={dataFine}
              format="DD/MM/YYYY"
              onChange={val => setDataFine(val)}
            />
          </LocalizationProvider>
        </Stack>
        {accountData ? (
          <Stack>
            <IntestazioneGrafici
              titolo={'Operazioni per Account'}
              sottotitolo={'Numero di operazioni per Account'}
            />
            <GraficoBarreFiltrato
              data={accountData.operazioniOperatore}
              filteredList={accountData.elencoStati}
            />
            <Stack sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
              <IntestazioneGrafici
                titolo={'Importi per Account'}
                sottotitolo={'Totale Importi per Account'}
              />
              <GraficoBarreOrizzontale
                value={accountData.importiOperatore}
                format={true}
                symbol={'\u00A0€'}
              />
            </Stack>
          </Stack>
        ) : (
          <></>
        )}
      </Card>
      {loadingError != null && <ActionErrorMessage error={loadingError} />}
      {isLoading ? <LoadingMask /> : <></>}
    </Stack>
  );
}
