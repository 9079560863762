import { z } from 'zod';
import useZod from '../../../hooks/useZod';

const formSchema = z.object({
  piva: z.string().min(1, { message: 'La partita iva è obbligatoria' }),
  cf: z.string().min(1, { message: 'Il codice fiscale è obbligatorio' }),
  ragione_sociale: z
    .string()
    .min(1, { message: 'La ragione sociale è obbligatoria' }),
  sede_indirizzo: z.string().min(1, { message: "L'indirizzo è obbligatorio" }),
  sede_civico: z.string().min(1, { message: 'Il civico è obbligatorio' }),
  sede_cap: z.string().min(1, { message: 'Il cap è obbligatorio' }),
  sede_citta: z.string().min(1, { message: 'La città è obbligatoria' }),
  sede_provincia: z.string().min(1, { message: 'La provincia è obbligatoria' }),
  email: z.string().email({ message: 'Inserisci un indirizzo email valido' }),
  telefono: z.string().min(1, { message: 'Il telefono è obbligatorio' }),
});

const useRegisterForm = data => {
  const { formData, setFormData, errors, handleInputChange, validate } = useZod(
    {
      schema: formSchema,
      initialFormData: data || {
        piva: '',
        cf: '',
        ragione_sociale: '',
        sede_indirizzo: '',
        sede_civico: '',
        sede_cap: '',
        sede_citta: '',
        sede_provincia: '',
        email: '',
        telefono: '',
      },
    }
  );

  const handleSubmit = () => {
    const validation = validate();
    if (validation.success) {
      return 1;
    }

    return -1;
  };
  return {
    setFormData,
    formData,
    errors,
    handleInputChange,
    handleSubmit,
  };
};

export default useRegisterForm;
