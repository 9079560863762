import { gray, orange, brand, red, green } from '../themePrimitives';

export const feedbackCustomizations = {
  MuiAlert: {
    styleOverrides: {
      root: () => ({
        // borderRadius: 10,
        // backgroundColor: orange[100],
        // color: theme.palette.text.primary,
        // border: `1px solid ${alpha(orange[300], 0.5)}`,
        // '& .MuiAlert-icon': {
        //   color: orange[500],
        // },
        // ...theme.applyStyles('dark', {
        //   backgroundColor: `${alpha(orange[900], 0.5)}`,
        //   border: `1px solid ${alpha(orange[800], 0.5)}`,
        // }),
        variants: [
          {
            props: {
              severity: 'info',
            },
            style: {
              '& .MuiAlert-icon': {
                color: brand[400],
              },
            },
          },
          {
            props: {
              severity: 'success',
            },
            style: {
              '& .MuiAlert-icon': {
                color: green[400],
              },
            },
          },
          {
            props: {
              severity: 'warning',
            },
            style: {
              '& .MuiAlert-icon': {
                color: orange[400],
              },
            },
          },
          {
            props: {
              severity: 'error',
            },
            style: {
              '& .MuiAlert-icon': {
                color: red[400],
              },
            },
          },
        ],
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          border: '1px solid',
          borderColor: theme.palette.divider,
        },
      }),
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: 8,
        borderRadius: 4,
        backgroundColor: gray[200],
        ...theme.applyStyles('dark', {
          backgroundColor: gray[800],
        }),
      }),
    },
  },
};
