import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ActionErrorMessage from '../../components/ActionErrorMessage';
import RichiestaModificaCard from './components/RichiestaModificaCard';
import LoadingMask from '../../components/LoadingMask';
import { getRichiestaModifica } from '../../redux/noleggi/api';

export default function RichiestaModifica() {
  const location = useLocation();
  const [richiestaModificaData, setRichiestaModificaData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);

  const onPageLoad = async () => {
    setIsLoading(true);
    try {
      const response = await getRichiestaModifica(location.state.id);
      setRichiestaModificaData(response);
    } catch (error) {
      setLoadingError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onPageLoad();
  }, [location.pathname]);

  return (
    <Stack>
      {loadingError != null && <ActionErrorMessage error={loadingError} />}
      {isLoading ? <LoadingMask /> : <></>}
      {richiestaModificaData != null ? (
        <RichiestaModificaCard richiestaModificaData={richiestaModificaData} />
      ) : (
        <></>
      )}
    </Stack>
  );
}
