import {
  TextField,
  Grid2 as Grid,
  FormControl,
  Typography,
  Box,
  FormLabel,
  Divider,
} from '@mui/material';
import SelectProvince from './SelectProvince';

const InserisciDatiFornitore = ({ hook }) => {
  const { formData, errors, handleInputChange } = hook;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="ragione_sociale">Ragione Sociale</FormLabel>
            <TextField
              placeholder="Ragione Sociale"
              name="ragione_sociale"
              variant="outlined"
              value={formData?.ragione_sociale}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.ragione_sociale}
              helperText={errors.ragione_sociale}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="cf">Codice Fiscale</FormLabel>
            <TextField
              placeholder="Codice Fiscale"
              name="cf"
              value={formData?.cf}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.cf}
              helperText={errors.cf}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="piva">Partita Iva</FormLabel>
            <TextField
              placeholder="Partita IVA"
              name="piva"
              value={formData?.piva}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.piva}
              helperText={errors.piva}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, mb: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: 'Poppins-SemiBold', fontSize: 16 }}
        >
          Inserisci Indirizzo:
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 3 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_indirizzo">Indirizzo Sede</FormLabel>
            <TextField
              placeholder="Indirizzo Sede"
              name="sede_indirizzo"
              value={formData?.sede_indirizzo}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_indirizzo}
              helperText={errors.sede_indirizzo}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 6, sm: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_civico">N. Civico</FormLabel>
            <TextField
              placeholder="N. Civico"
              name="sede_civico"
              value={formData?.sede_civico}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_civico}
              helperText={errors.sede_civico}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 6, sm: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_cap">CAP</FormLabel>
            <TextField
              placeholder="CAP"
              name="sede_cap"
              value={formData?.sede_cap}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_cap}
              helperText={errors.sede_cap}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_citta">Città</FormLabel>
            <TextField
              placeholder="Città"
              name="sede_citta"
              value={formData?.sede_citta}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_citta}
              helperText={errors.sede_citta}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 3 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="sede_provincia">Provincia</FormLabel>
            <SelectProvince
              name="sede_provincia"
              placeholder="Provincia"
              value={formData?.sede_provincia}
              onChange={handleInputChange}
              errors={errors.sede_provincia}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Divider orientation="horizontal" flexItem sx={{ mt: 4, mb: 3 }} />
    </Box>
  );
};

export default InserisciDatiFornitore;
