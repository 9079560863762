import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Step,
  StepLabel,
  Stepper,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close, ExpandMore, Search, Settings } from '@mui/icons-material';
import { toggleDrawerSupporto } from '../redux/app/slice';
import { useEffect, useMemo, useRef, useState } from 'react';

function SupportoDrawer({ headerRef, sideMenuRef }) {
  const dispatch = useDispatch();

  const drawerRef = useRef(null);
  const { drawerSupportoOpen } = useSelector(state => state.app);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const [search, setSearch] = useState('');
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const _headerHeight = headerRef?.current?.clientHeight ?? 0;
    setHeaderHeight(_headerHeight);
  }, [headerRef]);

  useEffect(() => {
    const _sidebarWidth = sideMenuRef?.current?.offsetWidth ?? 0;
    setSidebarWidth(_sidebarWidth);
  }, [sideMenuRef]);

  useEffect(() => {
    document.addEventListener('mousedown', e => {
      if (drawerRef) {
        //se clicco outside drawer lo chiudo
        const drawerPos = drawerRef?.current?.getBoundingClientRect();
        if (!drawerPos) return;
        if (e.pageX > drawerPos.x + drawerPos.width || e.pageY < drawerPos.y) {
          onToggleDrawer();
        }
      }
    });

    return () => {
      document.removeEventListener('mousedown', () => {});
    };
  }, []);

  const questions = [
    {
      title: 'Come richiedo il Noleggio?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
    },
  ];

  //usememo per filtrare le domande
  const filteredQuestions = useMemo(() => {
    return questions.filter(q =>
      q.title.toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);

  let steps = [
    { key: 1, title: 'PROPOSTA', subtitle: 'In Bozza' },
    { key: 2, title: 'PRATICA INOLTRATA', subtitle: 'In Valutazione' },
    { key: 3, title: 'PROPOSTA INOLTRATA', subtitle: 'In Perfezionamento' },
    { key: 4, title: 'PROPOSTA INOLTRATA', subtitle: 'In Attesa di Firma' },
    { key: 5, title: 'PRATICA CONFERMATA', subtitle: '' },
    {
      key: 6,
      title: 'MATERIALE CONSEGNATO',
      subtitle: 'In attesa di attivazione del Noleggio',
    },
    {
      key: 7,
      title: 'NOLEGGIO ATTIVO',
    },
  ];

  const onToggleDrawer = () => {
    dispatch(toggleDrawerSupporto());
  };

  return (
    <Drawer
      variant={drawerSupportoOpen && matchMd ? 'permanent' : 'temporary'}
      anchor="left"
      open={drawerSupportoOpen}
      onClose={onToggleDrawer}
      sx={{
        flexShrink: 0,
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'secondary.main',
          height: matchMd ? 'calc(100% - ' + headerHeight + 'px)' : '100%',
          position: 'fixed',
          top: matchMd ? headerHeight : 0,
          left: matchMd ? sidebarWidth : 0,
        },
      }}
    >
      <Stack
        ref={drawerRef}
        sx={{
          width: matchLg ? '40vw' : matchMd ? '60dvw' : '100vw',
          height: '100%',
          px: 2,
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent={'space-between'}
          alignItems="center"
          sx={{ py: 2 }}
        >
          <Stack direction="row" alignItems="center">
            <Settings sx={{ color: 'white' }} />
            <Typography variant="h6" color="white" sx={{ ml: 1 }}>
              Supporto e Manuali d'Uso
            </Typography>
          </Stack>
          {matchMd ? (
            <></>
          ) : (
            <IconButton size="small" onClick={onToggleDrawer}>
              <Close />
            </IconButton>
          )}
        </Stack>

        <Card sx={{ backgroundColor: 'white', p: 2 }}>
          <Typography color="primary" sx={{ fontWeight: 'bold' }}>
            Flusso di Noleggio:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            Visiona gli step per richiedere l'attivazione di un noleggio
          </Typography>

          <Stepper
            alternativeLabel
            sx={{ overflowY: 'auto', overflowX: 'hidden', py: 1, mt: 2 }}
          >
            {steps.map(label => (
              <Step key={label.key} sx={{ p: 0 }} active={0}>
                <StepLabel>
                  <Typography
                    sx={{
                      fontSize: 8,
                      display: 'block',
                      lineHeight: 1.1,
                    }}
                  >
                    {label.title}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: 8,
                      display: 'block',
                      lineHeight: 1.1,
                      mt: 0.5,
                    }}
                  >
                    {label.subtitle}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Card>

        <Divider sx={{ height: 2, backgroundColor: 'white', my: 3 }} />

        <Box>
          <Typography color="white" sx={{ fontWeight: 'bold', fontSize: 14 }}>
            Cerca velocemente risposte alle tue domande
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            color="darkest"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Come possiamo aiutarti?"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          {filteredQuestions.map((q, i) => (
            <Accordion
              key={i}
              sx={{
                p: 0,
                backgroundColor: 'primary.darkest',
                color: 'white',
                '&:hover': { backgroundColor: 'primary.dark' },
              }}
            >
              <AccordionSummary
                sx={{ '&:hover': { backgroundColor: 'primary.dark' } }}
                expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Stack flexDirection="row" alignItems="center">
                  <Box
                    sx={{
                      p: '4px',
                      backgroundColor: 'white',
                      borderRadius: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Settings
                      sx={{
                        zIndex: 1,
                        color: 'primary.main',
                        height: '18px',
                        width: '18px',
                      }}
                    />
                  </Box>

                  <Typography sx={{ ml: 1, fontWeight: 'bold' }}>
                    {q.title}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ ml: '35px', textAlign: 'justify' }}>{q.content}</Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box sx={{ flexGrow: 1 }}></Box>

        <Stack
          justifyContent={'space-between'}
          alignContent={'center'}
          alignItems={'center'}
          flexDirection={'row'}
          sx={{ mt: 2, mb: 4 }}
        >
          <Link
            href={'mailto:assistenza@doinnovation.it'}
            color="white"
            sx={{ fontWeight: 'bold' }}
          >
            Hai bisogno di aiuto?
          </Link>

          <Button
            variant="contained"
            color="whiteDark"
            size="small"
            sx={{ minWidth: '150px' }}
            onClick={() => {
              //open link email
              window.open('mailto:assistenza@doinnovation.it', '_blank');
            }}
          >
            CONTATTACI
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default SupportoDrawer;
