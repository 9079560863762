import { useEffect } from 'react';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getProduttori } from '../../../redux/produttori/operations';

const SelectProduttore = ({ name, placeholder, value, onChange, errors }) => {
  const { produttori } = useSelector(state => state.produttori);
  const dispatch = useDispatch();

  useEffect(() => {
    if (produttori.length == 0) {
      dispatch(getProduttori());
    }
  }, []);

  const _onChange = e => {
    onChange({
      target: {
        name: name,
        value: e.target.value,
        ragione_sociale: produttori.find(
          produttore => produttore.id == e.target.value
        ).ragione_sociale,
      },
    });
  };

  return (
    <>
      <Select
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={_onChange}
        sx={{ flex: 1 }}
      >
        {produttori?.map(produttore => (
          <MenuItem key={produttore.id} value={produttore.id}>
            {produttore.ragione_sociale}
          </MenuItem>
        ))}
      </Select>
      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </>
  );
};

export default SelectProduttore;
