import { Box, Stack, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import OperazioniPanel from './components/OperazioniPanel';
import InoltriPanel from './components/InoltriPanel';
import ClientiPanel from './components/ClientiPanel';
import AccountPanel from './components/AccountPanel';

export default function Reports() {
  const { user } = useAuth();

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, justifyContent: 'space-between' }}
      >
        <Box>
          <Typography component="h2" variant="h4">
            Report
          </Typography>
          <Typography variant="body1">
            Qui puoi visualizzare i report della tua attività.
          </Typography>
        </Box>
      </Stack>

      <OperazioniPanel />
      <InoltriPanel />
      <ClientiPanel />
      {user?.flg_admin && <AccountPanel />}
    </Box>
  );
}
