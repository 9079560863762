import { Box, Button, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import AccountTable from './components/AccountTable';
import { useNavigate } from 'react-router-dom';

export default function GestioneAccount() {
  const navigate = useNavigate();

  const onNuovoAccount = () => {
    navigate('/gestione-account/new');
  };

  const onEdit = account => {
    navigate('/gestione-account/edit', { state: { account: account } });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, justifyContent: 'space-between' }}
      >
        <Box>
          <Typography component="h2" variant="h4">
            Gestione Account
          </Typography>
          <Typography variant="body1">
            Censisci account alla piattaforma e personalizza i permessi per
            ognuno di essi.
          </Typography>
        </Box>

        <Button
          onClick={onNuovoAccount}
          startIcon={<Add />}
          variant="contained"
        >
          Nuovo account
        </Button>
      </Stack>

      <AccountTable onEdit={onEdit} />
    </Box>
  );
}
