import { createSlice } from '@reduxjs/toolkit';
import { getComunicazioni } from './operations';
import {
  createAsyncState,
  handlePending,
  handleFulfilled,
  handleRejected,
} from '../base';

const initialState = {
  comunicazioni: [],
  getComunicazioniAction: createAsyncState(),
};

const comunicazioniSlice = createSlice({
  name: 'comunicazioni',
  initialState,
  reducers: {
    clearAllComunicazioni(state) {
      state.comunicazioni = [];
    },
    clearComunicazione(state, action) {
      state.comunicazioni = state.comunicazioni.filter(
        comunicazione => comunicazione.id !== action.payload
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getComunicazioni.pending, state => {
        handlePending(state.getComunicazioniAction);
      })
      .addCase(getComunicazioni.fulfilled, (state, action) => {
        state.comunicazioni = action.payload;
        handleFulfilled(state.getComunicazioniAction, action);
      })
      .addCase(getComunicazioni.rejected, (state, action) => {
        handleRejected(state.getComunicazioniAction, action);
      });
  },
});

export const { clearAllComunicazioni, clearComunicazione } =
  comunicazioniSlice.actions;

export const comunicazioniReducer = comunicazioniSlice.reducer;
