import { FeedRounded } from '@mui/icons-material';
import { Box, Divider, Stack } from '@mui/material';
import MenuButton from './MenuButton';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifiche } from '../redux/notifiche/operations';
import { brand } from '../theme/themePrimitives';
import {
  toggleDrawerNotifiche,
  toggleDrawerComunicazioni,
} from '../redux/app/slice';
import { getComunicazioni } from '../redux/comunicazioni/operations';
import { ReactComponent as NotificheIconSvg } from '../assets/img/notifiche_icon.svg';

export default function Header({ forceShow = false, height = 50 }) {
  const dispatch = useDispatch();
  const { notifiche } = useSelector(state => state.notifiche);
  const { comunicazioni } = useSelector(state => state.comunicazioni);
  const { drawerNotificheOpen, drawerComunicazioniOpen } = useSelector(
    state => state.app
  );

  const notificheNonLette = notifiche.filter(n => n.data_lettura == null);

  useEffect(() => {
    dispatch(getNotifiche());
    dispatch(getComunicazioni());
  }, []);

  const onToggleDrawerNotifiche = e => {
    e.preventDefault();
    dispatch(toggleDrawerNotifiche());
  };

  const onToggleDrawerComunicazioni = e => {
    e.preventDefault();
    dispatch(toggleDrawerComunicazioni());
  };

  return (
    <Stack
      direction="row-reverse"
      sx={{
        display: { xs: forceShow ? 'flex' : 'none', md: 'flex' },
        alignItems: { xs: 'flex-start', md: 'center' },
        justifyContent: 'space-between',
      }}
      spacing={2}
    >
      <Box
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          height: height,
          pr: 4,
        }}
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MenuButton
            varian="icon"
            color="primary"
            sx={{
              m: 0.5,
              border: 'none',
              backgroundColor: 'primary.dark',
              color: 'white',
              borderRadius: '100%',
              width: '30px',
              height: '30px',
              '&:hover': {
                backgroundColor: brand[800],
              },
              '&:active': {
                backgroundColor: brand[900],
              },
            }}
            showBadge={comunicazioni.length > 0}
            badgeContent={comunicazioni.length}
            aria-label="Open notifications"
            onClick={onToggleDrawerComunicazioni}
          >
            <FeedRounded />
          </MenuButton>
          <Divider
            sx={{
              visibility: drawerComunicazioniOpen ? 'visible' : 'hidden',
              height: '3px',
              width: '20px',
              borderRadius: '4px',
              alignSelf: 'center',
              backgroundColor: 'primary.dark',
            }}
          />
        </Box>

        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
            ml: 2,
          }}
        >
          <MenuButton
            varian="icon"
            color="primary"
            sx={{
              m: 0.5,
              border: 'none',
              backgroundColor: 'primary.dark',
              color: 'white',
              borderRadius: '100%',
              width: '30px',
              height: '30px',
              '&:hover': {
                backgroundColor: brand[800],
              },
              '&:active': {
                backgroundColor: brand[900],
              },
            }}
            showBadge={notificheNonLette.length > 0}
            badgeContent={notificheNonLette.length}
            aria-label="Open notifications"
            onClick={onToggleDrawerNotifiche}
          >
            <NotificheIconSvg />
          </MenuButton>
          <Divider
            sx={{
              visibility: drawerNotificheOpen ? 'visible' : 'hidden',
              height: '3px',
              width: '20px',
              borderRadius: '4px',
              alignSelf: 'center',
              backgroundColor: 'primary.dark',
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
}
