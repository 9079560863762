import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

function CardInserimentoStep({ number, title, subtitle, component }) {
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ position: 'relative' }}>
      {matchMd && (
        <Card
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 10,
            height: 10,
            bgcolor: 'background.darkest',
            color: 'white',
            zIndex: 1,
            top: '40px',
          }}
        >
          <Typography variant="h6" component="h3">
            {number}
          </Typography>
        </Card>
      )}

      <Card sx={{ m: matchMd ? 1.5 : 0, bgcolor: 'background.default' }}>
        <CardContent sx={{ p: matchMd ? 2 : 0 }}>
          <Stack spacing={2}>
            <Typography variant="h6" component="h5">
              {title}
            </Typography>
            {subtitle}
            {component}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default CardInserimentoStep;
