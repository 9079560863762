import * as BaseNumberField from '@base_ui/react/NumberField';
import * as Field from '@base_ui/react/Field';
import { styled } from '@mui/system';
import { brand } from '../theme/themePrimitives';
import { grey } from '@mui/material/colors';
import { parseNumber } from '@base_ui/react/NumberField/utils/parse';
import { toValidatedNumber } from '@base_ui/react/NumberField/utils/validate';
import { Add, Remove } from '@mui/icons-material';
import { useRef } from 'react';
import { Box } from '@mui/material';

const formats = {
  EUR: {
    style: 'currency',
    currency: 'EUR',
  },
  decimal: {
    style: 'decimal',
  },
};

export default function DecimalInputDecorated({
  min = 0,
  max = 999999,
  step = 0.01,
  round = 0.01,
  onChange,
  value,
  readOnly,
  name,
  placeholder,
  format = null, //decimal or currency
  decorationButtons = false,
  LeftButtonIcon = Remove,
  RightButtonIcon = Add,
  minWidth = '50px',
  ...props
}) {
  const valueParsed = useRef(value);
  const formatStyle = formats[format] ?? formats['decimal'];

  const decorationButtonsStyle = {
    cursor: 'default',
    opacity: 'unset',
    pointerEvents: 'none',
  };
  const disabledButtonStyle = {
    cursor: 'not-allowed',
    opacity: '0.5',
    pointerEvents: 'none',
  };
  const defaultButtonStyle = {
    padding: 4,
    maxWidth: '40px',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  };
  const buttonStyle = decorationButtons
    ? { ...defaultButtonStyle, ...decorationButtonsStyle }
    : { ...defaultButtonStyle };

  const rightButtonStyle =
    valueParsed.current >= max && !decorationButtons
      ? { ...buttonStyle, ...disabledButtonStyle }
      : { ...buttonStyle };
  const leftButtonStyle =
    valueParsed.current <= min && !decorationButtons
      ? { ...buttonStyle, ...disabledButtonStyle }
      : { ...buttonStyle };

  const defaultIconStyle = {
    height: 20,
    width: 20,
  };

  const onChangePrep = e => {
    const parsed = parseNumber(e.target.value, formatStyle);
    const value = toValidatedNumber(parsed, {
      step: round,
      format: formatStyle,
      minWithDefault: min ?? Number.MIN_SAFE_INTEGER,
      maxWithDefault: max ?? Number.MAX_SAFE_INTEGER,
      minWithZeroDefault: min ?? 0,
    });
    return value;
  };

  return (
    <div>
      <Field.Root>
        <NumberField
          format={formatStyle}
          value={value}
          onValueChange={() => {
            onChange(valueParsed.current);
          }}
          min={min}
          max={max}
          name={name}
          step={step}
          onKeyUp={e => {
            valueParsed.current = onChangePrep(e);

            if (e.key === 'Enter') {
              e.preventDefault();
              onChange(valueParsed.current);
            }
          }}
          {...props}
        >
          <NumberFieldGroup>
            {LeftButtonIcon && (
              <NumberFieldDecrement
                style={leftButtonStyle}
                onClick={() => {
                  valueParsed.current = Math.max(
                    min,
                    valueParsed.current - step
                  );
                  onChange(valueParsed.current);
                }}
              >
                <LeftButtonIcon sx={defaultIconStyle} />
              </NumberFieldDecrement>
            )}
            <NumberFieldInput
              placeholder={placeholder}
              sx={{
                flex: 1,
                minWidth: minWidth,
                borderRadius:
                  LeftButtonIcon && RightButtonIcon
                    ? '0px'
                    : LeftButtonIcon
                      ? '0 4px 4px 0px'
                      : RightButtonIcon
                        ? '4px 0 0 4px'
                        : '4px',
              }}
              readOnly={readOnly}
            />
            {RightButtonIcon && (
              <NumberFieldIncrement
                style={rightButtonStyle}
                onClick={() => {
                  valueParsed.current = Math.min(
                    max,
                    valueParsed.current + step
                  );
                  onChange(valueParsed.current);
                }}
              >
                <RightButtonIcon sx={defaultIconStyle} />
              </NumberFieldIncrement>
            )}
          </NumberFieldGroup>
        </NumberField>
      </Field.Root>
    </div>
  );
}

const NumberField = styled(BaseNumberField.Root)`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1rem;
`;

const NumberFieldGroup = styled(BaseNumberField.Group)`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  border-radius: 4px;
  border: 1px solid ${grey[300]};
  border-color: ${grey[300]};
  overflow: hidden;

  &:focus-within {
    outline: 2px solid ${brand[100]};
    border-color: ${brand[400]};
  }

  .dark & {
    border: 1px solid ${grey[700]};
    border-color: ${grey[700]};

    &:focus-within {
      outline: 2px solid ${brand[800]};
      border-color: ${brand[400]};
    }
  }
`;

const NumberFieldInput = styled(BaseNumberField.Input)(
  ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    padding: 10px 12px;
    outline: 0;
    min-width: 0;
    width: 6rem;
    text-align: center;
  
    &:hover {
       box-shadow: 0px 2px 4px ${
         theme.palette.mode === 'dark'
           ? 'rgba(0,0,0, 0.5)'
           : 'rgba(0,0,0, 0.05)'
       };
    }
  
    &:focus {
       box-shadow: 0px 2px 4px ${
         theme.palette.mode === 'dark'
           ? 'rgba(0,0,0, 0.5)'
           : 'rgba(0,0,0, 0.05)'
       };
    }
  
    &:focus-visible {
      outline: 0;
       box-shadow: 0px 2px 4px ${
         theme.palette.mode === 'dark'
           ? 'rgba(0,0,0, 0.5)'
           : 'rgba(0,0,0, 0.05)'
       };
    }
  `
);

const buttonStyles = `
  display: flex;
  position: relative;
  border: none;
  font-weight: bold;
  transition-property: background-color, border-color, color;
  transition-duration: 100ms;
  padding: 0.5rem 0.75rem;
  flex: 1;
  align-self: stretch;
  background-color: ${grey[50]};
  color: ${grey[700]};
  margin: 0;
  font-family: math, sans-serif;

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .dark & {
    background-color: ${grey[800]};
    color: ${grey[300]};
    border-color: ${grey[700]};

    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &:hover:not([disabled]) {
    background-color: ${grey[100]};
    border-color: ${grey[200]};
    color: ${grey[800]};
  }

  &:active:not([disabled]) {
    background-color: ${grey[200]};
  }

  .dark {
    &:hover:not([disabled]) {
      background-color: ${grey[800]};
      border-color: ${grey[700]};
      color: ${grey[200]};
    }

    &:active:not([disabled]) {
      background-color: ${grey[700]};
    }
  }
`;

const NumberFieldDecrement = styled(Box)(
  () => `
  ${buttonStyles}
      border-right: 0;
`
);

const NumberFieldIncrement = styled(Box)(
  ({ theme }) => `
    ${buttonStyles}
    border-left: 0;
    background: ${theme.palette.primary.main};
    color: white;
    border-color: ${theme.palette.mode === 'dark' ? brand[400] : brand[300]};
 
    &:hover:not([disabled]) {
        background-color: ${brand[500]};
        border-color: ${brand[400]};
        color: white;
        }
}
    `
);
