import { useEffect } from 'react';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getClienti } from '../../../redux/clienti/operations';

const SelectClienti = ({ name, placeholder, value, onChange, errors }) => {
  const { clienti } = useSelector(state => state.clienti);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clienti.length == 0) {
      dispatch(getClienti());
    }
  }, []);

  const _onChange = e => {
    onChange({
      target: {
        name: name,
        value: e.target.value,
        ragione_sociale: clienti.find(cliente => cliente.id == e.target.value)
          .ragione_sociale,
      },
    });
  };

  return (
    <>
      <Select
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={_onChange}
        sx={{ flex: 1 }}
      >
        {clienti?.map(cliente => (
          <MenuItem key={cliente.id} value={cliente.id}>
            {cliente.ragione_sociale}
          </MenuItem>
        ))}
      </Select>
      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </>
  );
};

export default SelectClienti;
